<ng-content select=".input-group-prepend"></ng-content>
<input [ngModel]="filterTerm"
       (input)="onInput($event.target.value)" [disabled]="disabled"
       [placeholder]="placeholder" [id]="id"
       [attr.type]="searching ? 'text' : 'search'" autocomplete="off" class="form-control rounded-end"/>
<span class="input-group-text border-0 search-icon bg-transparent" *ngIf="!filterTerm">
  <span class="fa-light fa-search"></span>
</span>
<span *ngIf="showTooltip" class="input-group-text border-0 info-icon bg-transparent">
        <app-tooltip>
          <span class="fa fa-info-circle"></span>
          <span class="tooltipContent">
            <ul>
              <li>Put your search term between quotation marks to find an exact match ("search term")</li>
              <li>Use multiple words with spaces to match items containing all search terms (Algeciras AMAZONEH)</li>
              <li>Use OR to match items containing one or more search terms (Algeciras OR Barcelona)</li>
            </ul>
          </span>
        </app-tooltip>
</span>
<span class="input-group-text border-0 search-icon bg-transparent" *ngIf="searching">
  <span class="spinner-border spinner-border-sm text-secondary" role="status"></span>
</span>
<ng-content select=".invalid-feedback"></ng-content>
<ng-content select=".valid-feedback"></ng-content>
