import {Component, Input} from '@angular/core';
import {TaskMessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";
import {DeclarationMessageStatus} from "../../visit-overview/visit-overview-item/visit-overview.utils";

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent {
  @Input() messageStatus: TaskMessageStatus;
  @Input() messageStatuses: DeclarationMessageStatus[] = [];
}
