<div>
  <div class="declaration-block my-4">
    <h2>Good details</h2>
  </div>
</div>
<div class="row">

  <div class="col-md">
    <app-form-group label="Description">
      <input class="form-control" placeholder="Enter description" required [(ngModel)]="item.description"
             [readonly]="!isEditable"
             [appCompare]="itemFromCustoms.description" comparePreamble="Customs reported: ">
    </app-form-group>
    <app-form-group label="Number of packages">
      <input class="form-control" appDecimalNumber [maxDecimals]="0" [min]="0" placeholder="Enter Number of packages"
             [required]="!isTemplate" [(ngModel)]="item.numberOfPackages"
             [readonly]="!isEditable"
             [appCompare]="itemFromCustoms.numberOfPackages" comparePreamble="Customs reported: ">
    </app-form-group>
    <app-form-group label="Weight">
      <input class="form-control" appDecimalNumber [maxDecimals]="0" [min]="0" placeholder="Enter Weight"
             [required]="!isTemplate"
             [(ngModel)]="item.weight"
             [readonly]="!isEditable"
             [appCompare]="itemFromCustoms.weight" comparePreamble="Customs reported: ">
    </app-form-group>
    <app-form-group label="Package type">
      <app-select [(ngModel)]="item.packageType" dataKey="code" [required]="!isTemplate"
                  [optionsProvider]="refData.getPackageTypes"
                  [formatter]="refData.packageTypeFormatter"
                  [disabled]="!isEditable"
                  [appCompare]="itemFromCustoms.packageType" comparePreamble="Customs reported: "
                  [compareFormatter]="refData.packageTypeCompareFormatter"
      ></app-select>
    </app-form-group>
    <app-form-group label="Commodity">
      <app-search [(ngModel)]="item.classification" [inputFormatter]="refData.goodsClassificationFormatter"
                  [searchFunction]="refData.findClassifications"
                  dataKey="code" placeholder="Select a commodity"
                  [disabled]="!isEditable"
                  [appCompare]="itemFromCustoms.classification" comparePreamble="Customs reported: "
                  [compareFormatter]="refData.goodsClassificationCompareFormatter"></app-search>
    </app-form-group>
    <app-form-group label="Amount" *ngIf="item.classification && item.classification.sensitiveGoods">
      <input class="form-control" appDecimalNumber [maxDecimals]="0" [min]="1"
             [readonly]="!isEditable" placeholder="Enter Amount" required
             [(ngModel)]="item.amount">
    </app-form-group>
    <app-form-group *ngIf="declaration.data.securityActivated" label="Commercial reference">
      <input class="form-control" placeholder="Enter Commercial reference" [readonly]="!isEditable"
             [(ngModel)]="item.commercialReference">
    </app-form-group>
  </div>

  <div class="col-md">
    <app-form-group label="Country of export">
      <app-search [(ngModel)]="item.countryOfExport" [minCharacters]="0"
                  [searchFunction]="refData.findCountries"
                  [inputFormatter]="refData.countryFormatter"
                  dataKey="code" [resultFormatter]="refData.countryFormatter"
                  [disabled]="!isEditable"
                  [appCompare]="itemFromCustoms.countryOfExport" comparePreamble="Customs reported: "
                  [compareFormatter]="refData.countryCompareFormatter">
      </app-search>
    </app-form-group>
    <app-form-group label="Customs value">
      <div class="input-group">
        <div class="me-1">
          <app-select [ngModel]="item.declaredValue?.currency" [nullOption]="!item.declaredValue?.amount"
                      (ngModelChange)="item.declaredValue = (item.declaredValue || $any({})); item.declaredValue.currency = $event"
                      dataKey="unCode" [required]="!!item.declaredValue?.amount"
                      [optionsProvider]="refData.getCurrencies"
                      [disabled]="!isEditable"
                      [formatter]="refData.currenciesFormatter" [selectedFormatter]="refData.compactCurrenciesFormatter"
                      style="max-width: 5rem">
          </app-select>
        </div>
        <input [ngModel]="item.declaredValue?.amount" placeholder="Customs amount"
               (ngModelChange)="item.declaredValue = (item.declaredValue || $any({})); item.declaredValue.amount = $event"
               [required]="!!item.declaredValue?.currency"
               [readonly]="!isEditable"
               [appCompare]="itemFromCustoms.declaredValue" comparePreamble="Customs reported: "
               appDecimalNumber [maxDecimals]="2" class="form-control rounded">
      </div>
    </app-form-group>
    <app-form-group label="Marks and numbers">
      <input class="form-control" placeholder="Enter Marks and numbers" [(ngModel)]="item.marksAndNumbers"
             [readonly]="!isEditable"
             [appCompare]="itemFromCustoms.marksAndNumbers" comparePreamble="Customs reported: ">
    </app-form-group>
    <app-form-group label="Net weight">
      <input class="form-control" appDecimalNumber [maxDecimals]="0" [min]="0" placeholder="Enter Net weight"
             [(ngModel)]="item.netWeight"
             [readonly]="!isEditable"
             [appCompare]="itemFromCustoms.netWeight" comparePreamble="Customs reported: ">
    </app-form-group>
    <app-form-group label="Dangerous goods UN code">
      <input class="form-control" placeholder="Enter Dangerous goods UN code" [(ngModel)]="item.dangerousGoodsUnCode"
             [readonly]="!isEditable">
    </app-form-group>
    <app-form-group *ngIf="declaration.data.securityActivated" label="Circumstances">
      <app-select [(ngModel)]="item.paymentMethod"
                  [disabled]="!isEditable"
                  [options]="refData.securityPaymentMethods" [formatter]="refData.enumFormatter"></app-select>
    </app-form-group>
  </div>
</div>

<div>
  <div class="declaration-block my-4">
    <h2>Previous document</h2>
  </div>
</div>
<div class="row" *ngIf="item.previousDocument as prevDoc">
  <div class="col-md">
    <app-form-group label="Document type">
      <app-select [(ngModel)]="prevDoc.type" dataKey="code" placeholder="– Select Document type –"
                  [optionsProvider]="refData.getTransitPreviousDocumentTypes"
                  [formatter]="refData.transitDocumentTypesFormatter" [required]="!isTemplate"
                  [disabled]="!isEditable"
                  [appCompare]="itemFromCustoms.previousDocument.type" comparePreamble="Customs reported: "
                  [compareFormatter]="refData.transitDocumentTypesCompareFormatter">
      </app-select>
    </app-form-group>
    <app-form-group label="Document number">
      <input class="form-control" placeholder="Enter Document number" [required]="!isTemplate"
             [readonly]="!isEditable"
             [(ngModel)]="prevDoc.documentNumber"
             [appCompare]="itemFromCustoms.previousDocument.documentNumber" comparePreamble="Customs reported: ">
    </app-form-group>
  </div>

  <div class="col-md">
    <app-form-group label="Sequence">
      <input class="form-control" placeholder="Enter Sequence nr." [(ngModel)]="prevDoc.sequenceNumber"
             appDecimalNumber
             [readonly]="!isEditable"
             [required]="prevDoc.type && prevDoc.type.code && ['X-705', 'X-785', 'X-740', 'X-741', 'Z-ZZZ'].indexOf(prevDoc.type.code)!==-1 && !isTemplate"
             [appCompare]="itemFromCustoms.previousDocument.sequenceNumber" comparePreamble="Customs reported: ">
    </app-form-group>
  </div>
</div>

<app-transit-goods-documents [item]="item" [itemFromCustoms]="itemFromCustoms"
                             [isEditable]="isEditable"></app-transit-goods-documents>
