import {FacetStats, OverviewFilters} from "./facet-filter/facet-filter.component";
import lodash from "lodash";

export class FacetUtils {

  static isBooleanFacet(facet: FacetStats): boolean {
    return facet.values.every(v => ["false", "true"].includes(v.value))
  }

  static hasActiveFilters(overviewFilters: OverviewFilters<any>): boolean {
    return !lodash.isEmpty(Object.values(overviewFilters.filters || {}).filter(a => a)) || !lodash.isEmpty(overviewFilters.facets);
  }

  static defaultOverviewFilters<T>(filters: T = {} as T): OverviewFilters<T> {
    return {
      term: null,
      facets: {},
      filters: filters
    };
  }
}
