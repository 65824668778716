import {Component, Input} from '@angular/core';
import {TransitDeclaration, TransitGoodsItem} from "@portbase/bezoekschip-service-typescriptmodels";
import {lodash} from "../../common/utils";
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-transit-summary',
  templateUrl: './transit-summary.component.html',
  styleUrls: ['./transit-summary.component.css']
})
export class TransitSummaryComponent {
  @Input() keepTooltipOpen: boolean;
  model: TransitDeclaration;
  refData = PortvisitUtils;

  packageCount = () => 0;
  weight = () => 0;
  phase = (): string => null;
  phaseType = (): 'danger' | 'warning' | 'info' | 'success' | 'secondary' | 'dark' | 'light' => null;

  @Input() set setModel(model: TransitDeclaration) {
    this.model = model;
    this.phase = () => {
      switch (model.status.phase) {
        case 'declared': return 'scheduled';
        case 'cancelled':
          return model.status.cancelInitiatedByCustoms ? 'cancelled by customs' : 'cancel accepted';
        case 'undeclared':
          return 'draft';
      }
      return model.status.phase;
    };
    this.phaseType = () => {
      switch (model.status.phase) {
        case 'rejected':
        case 'releaseRejected':
          return 'danger';
        case 'inspection':
        case 'insufficientGuarantee':
        case 'proofOfArrivalRequested':
          return  'warning';
        case 'sent':
        case 'releaseRequested':
        case 'cancellationRequested':
          return 'info';
        case 'accepted':
        case 'inTransit':
        case 'proofOfArrivalSent':
        case 'arrived':
        case 'writtenOff':
          return model.status.phase === 'accepted' && this.showModeOfTransportWarning() ? 'warning' : 'success';
        case 'declared':
          return 'dark';
        case 'undeclared':
          return 'light';
        case 'cancelled':
          return 'secondary';
      }
    };
  }

  @Input() set item(item: TransitGoodsItem) {
    this.items = [item];
  }

  @Input() set items(items: TransitGoodsItem[]) {
    this.weight = () => lodash.sumBy(items, g => g.weight);
    this.packageCount = () => lodash.sumBy(items, g => g.numberOfPackages);
  }

  showModeOfTransportWarning(): boolean {
    return this.model?.status.phase == "accepted" && this.model?.data.departureTransport?.modeOfTransport === "NOT_SPECIFIED";
  }

  hasMrnStatus() {
    return !!this.model.status.mrnSent || !!this.model.status.mrnAccept || !!this.model.status.mrnReject;
  }
}
