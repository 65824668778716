import {Component, Input} from '@angular/core';
import {TransitDeclaration, TransitGoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-transit-good',
  templateUrl: './transit-good.component.html',
  styleUrls: ['./transit-good.component.css']
})
export class TransitGoodComponent {
  refData = PortvisitUtils;
  @Input() declaration: TransitDeclaration;
  @Input() item: TransitGoodsItem;
  @Input() itemFromCustoms: TransitGoodsItem;
  @Input() isTemplate: boolean = false;
  @Input() isEditable: boolean = false;
}
