<div class="row">
  <div class="d-flex" [ngClass]="editMode ? 'col-4' : 'col-3'">
    <div class="d-flex align-items-center border border-primary rounded-circle me-3"
         style="width: 1.5rem; height: 1.5rem;" [style.margin-top]="editMode ? '1.5rem' : '1rem'">
      <span class="text-primary text-center w-100">{{index + 1}}</span>
    </div>
    <div class="d-flex align-items-stretch flex-column">
      <app-form-field label="Country" [includeMargin]="false" [value]="routeInfo.country?.name" [editMode]="editMode">
        <app-search required [(ngModel)]="routeInfo.country" [searchFunction]="portVisitUtils.findCountries"
                    [resultFormatter]="portVisitUtils.countryFormatter" [inputFormatter]="countryFormatter"
                    (ngModelChange)="routeInfo.country = $event; refreshRoutes();">
        </app-search>
      </app-form-field>
    </div>
  </div>
  <ng-container *ngIf="editMode else displayMode">
    <div class="col-auto d-flex align-items-center" style="padding-top: 2em;">
      <button class="btn rounded-circle p-1 px-2 text-primary h1 mb-0"
              [disabled]="index === 0" (click)="moveUp()">
        <i class="fa-light fa-angle-up"></i>
      </button>
    </div>
    <div class="col-auto d-flex align-items-center" style="padding-top: 2em;">
      <button class="btn rounded-circle p-1 px-2 text-primary h1 mb-0"
              [disabled]="index + 1 === allRoutes.length" (click)="moveDown()">
        <i class="fa-light fa-angle-down"></i>
      </button>
    </div>
    <div *ngIf="allRoutes.length > 2" class="col-auto d-flex align-items-center" style="padding-top: 2em;">
      <button class="btn rounded-circle p-1 px-2 text-danger h1 mb-0" (click)="routeDeleted()">
        <i class="fa-light fa-trash-can"></i>
      </button>
    </div>
  </ng-container>
  <ng-template #displayMode>
    <ng-container *ngIf="routeInfo.placeOfDischarge || routeInfo.placeOfLoading || routeInfo.placeOfAcceptance">
      <div class="col-3 d-flex align-items-stretch flex-column places-wrapper">
        <app-form-field *ngIf="routeInfo.placeOfLoading" label="Port of loading" [includeMargin]="false"
                        [value]="getPlaceLabel(routeInfo.placeOfLoading)" [editMode]="false">
        </app-form-field>
        <app-form-field *ngIf="routeInfo.placeOfDischarge" label="Port of discharge" [includeMargin]="false"
                        [value]="getPlaceLabel(routeInfo.placeOfDischarge)" [editMode]="false">
        </app-form-field>
        <app-form-field *ngIf="routeInfo.placeOfAcceptance" label="Place of acceptance" [includeMargin]="false"
                        [value]="getPlaceLabel(routeInfo.placeOfAcceptance)" [editMode]="false">
        </app-form-field>
      </div>
    </ng-container>
    <ng-container *ngIf="routeInfo.dischargeTerminal || routeInfo.eta || routeInfo.etd">
      <div class="col d-flex align-items-stretch flex-column text-truncate">
        <ng-container *ngIf="routeInfo.dischargeTerminal else timeInfo">
          <app-form-field [label]="lastColumnLabel" [value]="routeInfo.dischargeTerminal.terminalName | sentenceCase"
                          [includeMargin]="false" [editMode]="false">
          </app-form-field>
        </ng-container>
        <ng-template #timeInfo>
          <app-form-field [label]="lastColumnLabel" [value]="formatDate(routeInfo.eta || routeInfo.etd)"
                          [includeMargin]="false" [editMode]="false">
          </app-form-field>
        </ng-template>
      </div>
    </ng-container>
  </ng-template>
</div>
