import {Component, ElementRef, OnInit} from '@angular/core';
import {ConsignmentUtils, GoodsItemWithHouseConsignments} from "../../consignment.utils";
import {
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3GetConsignment,
  IE3SaveConsignment,
  IE3TransportEquipment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {
  checkValidity,
  formDataSaved,
  openEditModal,
  publishEvent,
  sendCommand,
  sendQuery,
  showPreviousInStack
} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../master-consignment-details/master-consignment-details.component";
import {
  HouseConsignmentDetailsComponent,
  HouseConsignmentDetailsComponentData
} from "../house-consignment-details/house-consignment-details.component";

@Component({
  selector: 'app-equipment-consignment-details',
  templateUrl: './equipment-consignment-details.component.html',
  styleUrls: ['./equipment-consignment-details.component.scss']
})
export class EquipmentConsignmentDetailsComponent implements OnInit {
  utils = ConsignmentUtils;

  consignmentProcess: IE3ConsignmentProcess;
  houseConsignment: IE3ConsignmentHouseLevel;
  equipment: IE3TransportEquipment;
  data: EquipmentConsignmentDetailsComponentData;
  isNewEquipment: boolean;
  editMode: boolean;

  goodsItems: GoodsItemWithHouseConsignments[];

  sealIdentifiersFormatter = (values: string[]) => values?.join(" | ") || null;

  protected emptyIndicatorFormatter = (v: boolean) => v ? 'Empty' : 'Not empty';

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.data.cachedConsignmentProcess) {
      this.setConsignment(this.data.cachedConsignmentProcess);
    } else {
      sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment", <IE3GetConsignment>{
        consignmentProcessId: this.data.consignmentProcessId
      }, {caching: false}).subscribe((c: IE3ConsignmentProcess) => this.setConsignment(c));
    }
  }

  remove = () => {

  }

  toggleEdit = () => {
    this.editMode = !this.editMode && !this.consignmentProcess.cancelled;
  }

  save = () => {
    if (this.isValid) {
      this.processModel();
      if (ConsignmentUtils.hasBeenDeclared(this.consignmentProcess)) {
        if (this.houseConsignment) {
          this.openHouseConsignment();
        } else {
          this.openMasterConsignment();
        }
      } else {
        sendCommand("com.portbase.bezoekschip.common.api.consignments.commands.SaveConsignment", <IE3SaveConsignment>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignment: this.consignmentProcess.consignmentMasterLevel,
          filing: this.consignmentProcess.filing
        }, () => {
          formDataSaved();
          if (this.houseConsignment) {
            this.openHouseConsignment();
          } else {
            this.openMasterConsignment();
          }
        });
      }
    }
  }

  openMasterConsignment = () => {
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    showPreviousInStack({
      amount: 2,
      endReached: () =>
        openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
          cachedConsignmentProcess: this.consignmentProcess
        }, {
          warnOnClose: true,
          currentToStack: true
        })
    });
  }

  openHouseConsignment = () => {
    publishEvent('cachedConsignmentUpdated', this.consignmentProcess);
    showPreviousInStack({
      amount: 1,
      endReached: () =>
        openEditModal(HouseConsignmentDetailsComponent, <HouseConsignmentDetailsComponentData>{
          consignmentProcessId: this.consignmentProcess.consignmentProcessId,
          consignmentNumber: this.houseConsignment.consignmentNumber
        }, {
          warnOnClose: true,
          currentToStack: true
        })
    });
  }

  get isEditable() {
    return ConsignmentUtils.isEditable(this.consignmentProcess);
  }

  get oversize() {
    return this.equipment.oversize != null;
  }

  set oversize(oversize: boolean) {
    this.equipment.oversize = oversize ? {} : null;
  }

  get isValid(): boolean {
    return checkValidity(this.elementRef);
  }

  processModel = (): void => {
    this.consignmentProcess.consignmentMasterLevel.transportEquipmentMap[this.equipment.containerIdentificationNumber] = this.equipment;
    if (this.houseConsignment && !this.houseConsignment.transportEquipmentNumbers.includes(this.equipment.containerIdentificationNumber)) {
      this.houseConsignment.transportEquipmentNumbers.push(this.equipment.containerIdentificationNumber);
    }
  }

  private createEquipment = (): IE3TransportEquipment => {
    return {
      sealIdentifiers: [],
      empty: true
    };
  }

  private setConsignment = (c: IE3ConsignmentProcess) => {
    this.consignmentProcess = c;
    this.houseConsignment = c.consignmentMasterLevel.consignmentsHouseLevel
      .find(c => c.consignmentNumber === this.data.houseConsignmentNumber);
    this.equipment = c.consignmentMasterLevel.transportEquipmentMap[this.data.equipmentNumber];
    if (!this.equipment) {
      this.isNewEquipment = true;
      this.editMode = true;
      this.equipment = this.createEquipment();
    }
    const goodsItems = (this.houseConsignment
      ? this.houseConsignment.goodsItems
      : ConsignmentUtils.allGoodsItems(this.consignmentProcess)).filter(g => g.goodsPlacements.find(
      p => p.containerIdentificationNumber === this.equipment.containerIdentificationNumber));
    this.goodsItems = ConsignmentUtils.toConsignmentGoodsItemsSummaries(goodsItems)
      .map(g => (<GoodsItemWithHouseConsignments>{
        goodsItem: g,
        houseConsignments: this.houseConsignment ? [] : this.consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
          .filter(h => h.goodsItems.some(
            hg => hg.commodity?.commodityCode?.harmonizedSystemSubHeadingCode === g.commodity?.commodityCode?.harmonizedSystemSubHeadingCode
              && hg.goodsPlacements.some(gp => gp.containerIdentificationNumber === this.equipment.containerIdentificationNumber))
          )
          .map(h => h.consignmentNumber)
      }));
  }

  get isDutchPort() {
    return ConsignmentUtils.isDutchPort(this.consignmentProcess?.consignmentMasterLevel?.placeOfUnloading);
  }
}

export interface EquipmentConsignmentDetailsComponentData {
  consignmentProcessId: string;
  equipmentNumber: string;
  houseConsignmentNumber: string;
  cachedConsignmentProcess: IE3ConsignmentProcess;
}
