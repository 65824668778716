<nav [ngClass]="sticky ? 'sticky-top' : ''">
  <div class="nav nav-tabs" role="tablist" style="overflow-x: auto; overflow-y:hidden; flex-wrap: nowrap;">
    <a *ngFor="let tab of tabs; index as i; trackBy: trackByIndex" (click)="activateTab(tab, i)"
       [ngClass]="{active: tab == activeComponent, 'text-invalid': tab['ngInvalid'], 'ng-invalid': tab['ngInvalid']}" class="nav-link" style="cursor: pointer; white-space: nowrap;">
      <ng-template [ngTemplateOutlet]="tab.tabHeader"></ng-template>
    </a>
  </div>
</nav>

<ng-container *ngIf="alwaysRender else renderActiveOnly">
  <ng-container *ngFor="let tab of tabs; index as i; trackBy: trackByIndex">
    <div [ngClass]="tab == activeComponent ? ' ' : 'd-none'" [appValidator]="tab">
      <ng-template [ngTemplateOutlet]="tab.templateRef"></ng-template>
    </div>
  </ng-container>
</ng-container>
<ng-template #renderActiveOnly>
  <ng-template [ngTemplateOutlet]="activeComponent.templateRef"></ng-template>
</ng-template>
