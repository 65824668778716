<div class="row px-3">
  <label class="col sub-text text-uppercase d-inline-flex mb-2">Filters</label>
  <button *ngIf="facetUtils.hasActiveFilters(overviewFilters)" class="col-auto ms-auto btn btn-plain text-primary py-0" (click)="clearFilters()">Clear filters</button>
</div>
<div class="row-md g-0 px-2 d-flex flex-column">
  <ng-container *ngTemplateOutlet="specificFacetFiltersTemplate"></ng-container>
  <ng-container *ngFor="let facet of facets; index as i; trackBy: trackByFacetName">
    <div class="col-md-12">
      <app-facet-filter [facet]="facet" (selectedValuesChange)="onFacetValuesChange(facet, $event)"
                        [selectedValues]="overviewFilters?.facets[facet.name]"></app-facet-filter>
    </div>
  </ng-container>
  <div class="col-md-12" *ngIf="hasOtherFacetValueSelected || otherFacets?.length > 0">
    <div class="dropdown">
      <button class="btn btn-bg-secondary text-start d-flex justify-content-between align-items-center w-100 p-2" type="button"
              [ngClass]="{'active': hasOtherFacetValueSelected}" data-bs-toggle="dropdown">
        <div class="d-flex flex-column mw-0 flex-grow-1 me-3">
          <span class="d-inline-block text-truncate">Other</span>
          <div *ngIf="hasOtherFacetValueSelected" class="mt-1 text-secondary d-inline-block text-truncate">
            {{otherValuesSelectedFormatted}}
          </div>
        </div>
        <i *ngIf="!hasOtherFacetValueSelected" class="fa fa-fw fa-plus text-secondary"></i>
      </button>
      <div class="dropdown-menu min-w-100" [style.max-height.px]="320">
        <ul class="list-group">
          <ng-container *ngFor="let facet of otherFacets; index as i; trackBy: trackByFacetName">
            <li class="list-group-item list-group-item-action border-0 d-flex align-items-stretch p-0">
              <app-facet-filter-boolean-value [facet]="facet" [formattedFacetName]="facetNameFormatter(facet.name)"
                                              [selectedValues]="overviewFilters?.facets[facet.name]"
                                              (valueSelected)="onFacetValuesChange(facet, $event)"
                                              class="w-100 h-100"></app-facet-filter-boolean-value>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
