<ng-container *ngIf="messageStatus !== 'DISABLED'">
  <ng-container *ngIf="showTooltip else content">
    <app-tooltip class="formGroupLabel" placement="top">
      <app-task-message-status-icon [messageStatus]="messageStatus"></app-task-message-status-icon>
      <span class="d-none d-sm-inline-block">{{label || messageStatusLabel | sentenceCase}}</span>
      <div class="tooltipContent text-start">
        <span class="d-block fw-bold">{{header}}</span>
        <span class="d-block">{{reason}}</span>
      </div>
    </app-tooltip>
  </ng-container>

  <ng-template #content>
    <app-task-message-status-icon [messageStatus]="messageStatus"></app-task-message-status-icon>
    <span class="d-inline-block" [style.min-width.px]="62">{{label || messageStatusLabel | sentenceCase}}</span>
  </ng-template>
</ng-container>
