<span class="d-block fw-regular mb-3">{{label}}</span>
<ng-container *ngIf="supplementaryDeclarant else noSupplementaryDeclarant">
  <div *ngIf="supplementaryDeclarant" class="card form-group">
    <div class="card-body p-4">
      <div class="row mb-3">
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Identification number" [includeMargin]="false"
                          [value]="supplementaryDeclarant.identificationNumber" [editMode]="editMode && declarantEditMode">
            <input class="input-group rounded border form-control" [(ngModel)]="supplementaryDeclarant.identificationNumber" required/>
          </app-form-field>
        </div>
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Filing type" [includeMargin]="false" [value]="supplementaryDeclarant.supplementaryFilingType"
                          [editMode]="editMode && declarantEditMode" [formatter]="portVisitUtils.enumFormatter">
            <app-select [(ngModel)]="supplementaryDeclarant.supplementaryFilingType" [options]="utils.supplementaryFilingTypes"
                        [formatter]="portVisitUtils.enumFormatter" required></app-select>
          </app-form-field>
        </div>
      </div>
      <div *ngIf="editMode && declarantEditMode" class="row mt-3">
        <div class="col-auto ms-auto">
          <button type="button" class="btn btn-outline-secondary" aria-label="Cancel" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" (click)="save()">Save</button>
        </div>
      </div>
      <button *ngIf="editMode && !declarantEditMode" type="button" class="btn btn-tertiary position-absolute end-0 top-0 me-2 mt-2 p-1"
              (click)="toggleEditMode()">
        <i class="fa fa-fw fa-light fa-pencil"></i>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #noSupplementaryDeclarant>
  <span class="d-block mb-3">N/A</span>
</ng-template>
