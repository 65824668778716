<button *ngIf="this.messageStatus !== 'DISABLED'" onPopover data-bs-toggle="popover" [popoverContent]="popoverContent"
        placement="top"
        popoverClass="pop-auto-container" class="btn btn-link link-dark p-0">
  <div class="d-inline-block d-xl-none">
    <app-task-message-status-icon [messageStatus]="messageStatus"></app-task-message-status-icon>
  </div>
  <span class="d-none d-sm-inline-block text-start">
    <ng-content></ng-content>
  </span>
  <div class="d-none d-xl-inline-block">
    <ng-container *ngIf="cargoHandlingStatus && cargoHandlingStatus.taskStatus !== 'DISABLED'">
      <app-task-message-status-icon [messageStatus]="cargoHandlingStatus.taskStatus"></app-task-message-status-icon>
    </ng-container>
    <ng-container>
      <div class="d-inline-block">
        <app-task-message-status-icon [messageStatus]="messageStatus"></app-task-message-status-icon>
      </div>
    </ng-container>
  </div>
</button>

<ng-template #popoverContent>
  <div class="w-100 h-100 text-start p-1 pe-4" [style.max-width.px]="360" [style.min-width.px]="terminalMessageStatusEnabled ? 360 : 0">
    <div class="btn-close-wrapper rounded-circle position-absolute end-0 top-0 mt-2 me-2">
      <button type="button" class="btn-close" data-bs-toggle="popover" aria-label="Close"></button>
    </div>
    <ng-container *ngIf="terminalMessageStatusEnabled">
      <span class="d-block fw-medium mb-2">Terminal visit</span>
      <ng-container *ngFor="let messageStatus of terminalMessageStatuses">
        <div *ngIf="messageStatus.taskStatus !== 'DISABLED'" class="message-status d-flex mb-2 flex-column align-items-stretch">
          <div class="d-flex align-items-stretch justify-content-between" [style.height.px]="24">
            <div class="d-inline-flex align-self-start" [style.width.px]="160">
              <span class="text-truncate">{{messageStatus.name | sentenceCase}}</span>
            </div>
            <app-task-message-status [messageStatus]="messageStatus.taskStatus"
                                     [showTooltip]="false" class="d-flex justify-content-end"></app-task-message-status>
          </div>
          <div class="d-inline-flex flex-grow-1 flex-column row g-2">
            <div *ngFor="let message of messageStatus.messages" class="d-flex col-12">
              <i class="fa fa-fw fa-light fa-comment me-1 d-flex-inline"></i>
              <span class="d-flex-inline" style="line-height: 1.25rem">{{message}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="cargoHandlingStatus && cargoHandlingStatus.taskStatus !== 'DISABLED'" class="mb-3 message-status">
      <span class="d-block fw-medium mb-2">{{cargoHandlingStatus.name | sentenceCase}}</span>
      <app-task-message-status [messageStatus]="cargoHandlingStatus.taskStatus"
                               [reason]="cargoHandlingStatus.reason" [showTooltip]="false"></app-task-message-status>
    </div>
  </div>
</ng-template>
