<app-table-view [fields]="fieldsDefinition" [data]="_data" [recordTrackBy]="trackByContainerNumber"
                (termUpdated)="term = $event" (addButtonClicked)="edit(null)"
                [editable]="editable" [editMode]="editMode" [headerTemplate]="headerTemplate"></app-table-view>

<ng-template #headerTemplate>
  <button *ngIf="houseConsignmentNumber || showPlacement" class="btn btn-outline-primary text-decoration-none me-3"
          (click)="openLinkModal()">
    <i class="button-icon fa-light fa-link"></i>
    <span class="ps-2 d-none d-md-inline">Link</span>
  </button>
</ng-template>

<ng-template #equipmentRef let-equipment="value">
  <div class="container-wrapper">
    <div class="d-inline-flex placement-item pe-2">
      <span class="sub-text me-2">
        <i class="fa fa-fw fa-light fa-xl" [ngClass]="utils.getEquipmentIconCls(equipment.sizeType, equipment.empty)"></i>
      </span>
      <span class="d-inline-block text-truncate align-self-end cursor-pointer underline-tertiary link-underline-tertiary link-primary text-body"
            (click)="editEquipment(equipment.containerIdentificationNumber)">
        <app-highlight [term]="term" [result]="equipment.containerIdentificationNumber"></app-highlight>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #goodsRef let-goods="value">
  <app-consignment-goods-column [term]="term" [goods]="goods"></app-consignment-goods-column>
</ng-template>

<ng-template #actionColumnRef let-record="value">
  <a class="btn rounded-circle p-1 text-primary h1 mb-0 action-btn" type="button" (click)="edit(record)">
    <i class="fa-fw fa-light fa-angle-right"></i>
  </a>
</ng-template>

<ng-template #houseConsignmentRef let-record="value">
  <app-consignment-house-column [houseConsignments]="record" [consignmentProcessId]="consignmentProcess.consignmentProcessId"></app-consignment-house-column>
</ng-template>

<ng-template #equipmentLinkModalContent>
  <app-consignment-equipments-link-table [data]="equipmentLinkData" [consignmentProcess]="consignmentProcess"
                                         [houseConsignmentNumber]="houseConsignmentNumber"></app-consignment-equipments-link-table>
  <div class="row w-100 gx-0 position-absolute bottom-0 py-3 border-top d-flex justify-content-end px-5">
    <div class="col-auto mx-1">
      <button class="btn btn-outline-info mx-1" (click)="closeLinkModal()" type="button">
        Cancel
      </button>
    </div>
    <div class="col-auto mx-1">
      <button class="btn btn-primary mx-1" (click)="updateLink()" type="button">
        Link/Unlink
      </button>
    </div>
  </div>
</ng-template>
