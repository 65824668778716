import {Component, Input} from '@angular/core';
import {Berth, BerthVisit} from '@portbase/bezoekschip-service-typescriptmodels';
import {AbstractValueAccessorComponent} from 'src/app/common/component/value-accessor.component';
import {sendQuery} from 'src/app/common/utils';
import {VisitContext} from '../../visit-context';

@Component({
	selector: 'app-select-berth',
	templateUrl: './select-berth.component.html',
	styleUrls: ['./select-berth.component.css']
})
export class SelectBerthComponent extends AbstractValueAccessorComponent<BerthVisit> {

	context = VisitContext;

	@Input()
	berthVisit: BerthVisit;

	@Input()
	isBerthOfTransfer: boolean;

	@Input()
	firstBerth: boolean;

	private readonly _portUnCode: string;

	constructor() {
		super();
		this._portUnCode = VisitContext.visit.portOfCall.port.locationUnCode;
	}

	ngOnInit() {
	}

	get value(): BerthVisit {
		return this.berthVisit;
	}

	writeValue(value: BerthVisit): void {
		this.berthVisit = value;
	}

	public onBerthChanged(event: Berth, currentBerth: Berth): void {
		if(event) {
			if (this.firstBerth) {
				if (this.context.hasEntryDependency() && currentBerth && event.berthGroupCode !== currentBerth.berthGroupCode) {
					this.context.clearDependency();
				}
				this.context.updateCalculatedEtas();
			}
      if (event.bollards && this.berthVisit.bollardFrom && this.berthVisit.bollardTo) {
        let centerOfVessel = (this.berthVisit.bollardTo + this.berthVisit.bollardFrom) / 2;
        if (!(centerOfVessel > event.bollards.start && centerOfVessel < event.bollards.end)) {
          this.berthVisit.bollardFrom = null;
          this.berthVisit.bollardTo = null;
        }
      }
			this.berthVisit.berth = event;
		}
	}

  berthInputFormatter = (berth: Berth) => berth && berth.code ? (berth.name + ' – ' + (berth.shortName ? berth.shortName : berth.code) + (berth.bollards ? ' (' + berth.bollards.start + '-' + berth.bollards.end + ')' : '')) : '';

	searchBerth = (term) => sendQuery(
		'com.portbase.bezoekschip.common.api.visit.FindBerthsAndBuoys',
		{term: term, portUnCode: this._portUnCode}
	);

}
