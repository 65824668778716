import {Component, OnInit} from '@angular/core';
import {AppContext} from "../../../app-context";
import {
  IE3ConsignmentProcessSummary,
  IE3ConsignmentsResult,
  IE3FindConsignments
} from "@portbase/bezoekschip-service-typescriptmodels";
import {Observable} from "rxjs";
import {openEditModal, sendQuery} from "../../../common/utils";
import {map} from "rxjs/operators";
import {WebsocketService} from "../../../common/websocket.service";
import {SentenceCasePipe} from "../../../common/sentence-case.pipe";
import {ConsignmentUtils} from "../consignment.utils";
import {ActivatedRoute, Router} from "@angular/router";
import lodash from "lodash";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../details/master-consignment-details/master-consignment-details.component";
import {FacetUtils} from "../../common/facets/facet-utils";
import {FacetedOverview} from "../../common/overview/faceted-overview";
import {FacetedOverviewFilters} from "../../common/overview/overview.component";

@Component({
  selector: 'app-consignments-overview',
  templateUrl: './consignments-overview.component.html',
  styleUrls: ['./consignments-overview.component.scss'],
  providers: [WebsocketService]
})
export class ConsignmentsOverviewComponent extends FacetedOverview<IE3ConsignmentProcessSummary, ConsignmentOverviewFilters> implements OnInit {
  appContext = AppContext;
  data: IE3ConsignmentProcessSummary[] = [];

  constructor(private websocket: WebsocketService<IE3ConsignmentProcessSummary>, private router: Router, private route: ActivatedRoute) {
    super();
    if (AppContext.isProduction() && !AppContext.isAdmin()) {
      router.navigateByUrl("/");
    }
    route.params.subscribe(params => {
      route.queryParams.subscribe(queryParams => {
        const crn = params["crn"];
        if (crn) {
          this.filters.overviewFilters.facets["crn"] = [crn];
        } else {
          this.setLocalStorageKey("consignment-overview-filters");
        }
      })
    });
  }

  getData = (term: string): Observable<IE3ConsignmentsResult> => {
    if (term) {
      this.data = [];
    }
    if (!this.filters.overviewFilters) {
      this.filters.overviewFilters = FacetUtils.defaultOverviewFilters();
    }
    this.loading = true;
    this.saveFiltersInLocalStorage(this.filters);
    return sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.FindConsignments", <IE3FindConsignments>{
      term: term,
      from: this.from,
      maxHits: this.maxItems,
      facetFilters: this.getFacetFilters()
    }, {showSpinner: true}).pipe(map((result: IE3ConsignmentsResult) => {
      this.loading = false;
      return result;
    }));
  };

  ngOnInit(): void {
    this.websocket.initialise(`/api/ui/consignments`,
      (update: IE3ConsignmentProcessSummary) => this.updateFromWebsocket(update), true);
  }

  createConsignment = () => openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
    consignmentProcessId: null
  });

  renderRecords = (result: IE3ConsignmentsResult, append: boolean) => {
    this.saveFiltersInLocalStorage(this.filters);
    if (result.consignments.length < this.maxItems) {
      this.endReached = true;
    }
    this.data = append ? (this.data || []).concat(result.consignments) : result.consignments;
    this.setFacetsFromResult(result.facets);
  }

  trackByRecord(index: number, record: IE3ConsignmentProcessSummary): any {
    return record.masterConsignment.consignmentNumber;
  }

  private updateFromWebsocket(update: IE3ConsignmentProcessSummary) {
    const existingConsignment = this.data.find(
      c => c.consignmentProcessId === update.consignmentProcessId);
    if (existingConsignment) {
      this.data[this.data.indexOf(existingConsignment)] = update;
    }
  }

  nameFormatter = (name: string): string => {
    switch (name) {
      case "iamConnectedId": return "Organisation";
      case "placeOfLoading": return "Place of loading";
      case "dischargeTerminalName": return "Discharge terminal";
      case "filingType": return "Filing type";
      case "houseConsignmentsCount": return "Number of house consignments";
      case "equipmentCount": return "Number of equipments";
      case "crn": return "Call reference number";
      case "masterConsignment/customsProcess": return "Customs process";
      case "masterConsignment/customsStatus": return "Customs status";
      default: return SentenceCasePipe.formatWithSpaces(name);
    }
  }

  getValueFormatter = (name: string): (value: string) => string => {
    switch (name) {
      case "filingType": return ConsignmentUtils.filingTypeFormatter;
      case "dischargeTerminalName": return SentenceCasePipe.format;
      case "masterConsignment/customsProcess": return ConsignmentUtils.getCustomsProcessCode
      case "masterConsignment/customsStatus": return ConsignmentUtils.getCustomsStatusCode
      default: return null;
    }
  }

  getHiddenFacets = (): string[] => {
    const hiddenFields: string[] = ["count", "equipmentCount", "houseConsignmentsCount"];
    if (!AppContext.isAdmin()) {
      return hiddenFields.concat(["iamConnectedId"]);
    }
    return hiddenFields;
  }

  get masterConsignmentCount(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "count")?.values
      .map(v => v.count)) || 0
  }

  get houseConsignmentCount(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "houseConsignmentsCount")?.values
      .map(v => v.count * parseInt(v.value))) || 0
  }

  get equipmentCount(): number {
    return lodash.sum(this.allFacets?.find(f => f.name === "equipmentCount")?.values
      .map(v => v.count * parseInt(v.value))) || 0
  }
}

interface ConsignmentOverviewFilters extends FacetedOverviewFilters<any> {
}
