import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import moment from "moment";
import {
  IE3ConsignmentProcessSummary,
  IE3ConsignmentStatus,
  IE3RefreshConsignmentProcessSummary,
  TaskMessageStatus
} from "@portbase/bezoekschip-service-typescriptmodels";
import lodash from "lodash";
import {CheckboxSelectionState, openEditModal, sendCommand} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../../details/master-consignment-details/master-consignment-details.component";
import {ConsignmentUtils} from "../../consignment.utils";
import {DeclarationMessageStatus} from "../../../visit-overview/visit-overview-item/visit-overview.utils";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";

@Component({
  selector: 'app-master-consignment-overview-item',
  templateUrl: './master-consignment-overview-item.component.html',
  styleUrls: ['./master-consignment-overview-item.component.scss']
})
export class MasterConsignmentOverviewItemComponent {
  utils = ConsignmentUtils;
  appContext = AppContext;

  @Input() term: string;
  @Input() depth: number;
  @Input() selectable: boolean;
  @Input() collapsible: boolean;

  paddingPerStep: number = 20;
  collapsed: boolean = true;

  protected _consignment: IE3ConsignmentProcessSummary;
  protected _ensStatus: DeclarationMessageStatus;
  protected _temporaryStorageStatus: DeclarationMessageStatus;
  protected _ensStatuses: DeclarationMessageStatus[] = [];
  protected _temporaryStorageStatuses: DeclarationMessageStatus[];

  formatDate = (dateString: string): string => !!dateString ? moment(dateString).format("DD MMM HH:mm") : null;

  @Input()
  set consignment(consignment: IE3ConsignmentProcessSummary) {
    this._consignment = consignment;
    this._ensStatus = this.getEnsStatus();
    this._ensStatuses = [this._ensStatus];
    this._temporaryStorageStatus = this.getTemporaryStorageStatus();
    this._temporaryStorageStatuses = [this._temporaryStorageStatus];
  }

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  get visitLabel(): string {
    return this._consignment.masterConsignment.voyageNumber ? "Voyage number" : "Call reference number";
  }

  get customsStatusValue(): string {
    const customsStatus = ConsignmentUtils.getCustomsStatusCode(this._consignment.masterConsignment.customsStatus);
    const customsProcess = ConsignmentUtils.getCustomsProcessCode(this._consignment.masterConsignment.customsProcess);
    return [customsStatus, customsProcess].filter(a => a).join(" / ");
  }

  get terminalValue(): string {
    const time = this._consignment.ataPort || this._consignment.etaPort;
    const terminalName = SentenceCasePipe.format(this._consignment.masterConsignment.dischargeTerminal?.terminalName);
    return time && terminalName ? `${this.formatDate(time)}, ${terminalName}` : null;
  }

  get portOfLoading() {
    return this._consignment.masterConsignment.placeOfLoading
      ? `${this._consignment.masterConsignment.placeOfLoading.name} (${this._consignment.masterConsignment.placeOfLoading.countryUnCode})`
      : "";
  }

  get equipmentCount() {
    return lodash.sum([this._consignment.masterConsignment.equipments.length]);
  }

  get totalWeight() {
    return lodash.round(lodash.sum(this._consignment.masterConsignment.goodsItems.map(g => g.grossWeight)
      .concat(lodash.flatMap(this._consignment.masterConsignment.houseConsignments,
        h => h.goodsItems.map(g => g.grossWeight)))), 1);
  }

  get selectionState(): CheckboxSelectionState {
    const subItems = this.getSubItems();
    if (subItems.length > 0 && subItems.every(s => s["selected"] === CheckboxSelectionState.selected)) {
      return CheckboxSelectionState.selected;
    }
    if (subItems.length > 0 && subItems.some(s => [CheckboxSelectionState.selected, CheckboxSelectionState.indeterminate].includes(s["selected"]))) {
      return CheckboxSelectionState.indeterminate;
    }
    return CheckboxSelectionState.unselected;
  }

  set selectionState(value: CheckboxSelectionState) {
    this._consignment["selected"] = value;
    if (value === CheckboxSelectionState.selected || value === CheckboxSelectionState.unselected) {
      this.getSubItems().forEach(s => s["selected"] = value);
    }
  }

  editConsignment = () => openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
    consignmentProcessId: this._consignment.consignmentProcessId,
  }, {
    warnOnClose: true,
    currentToStack: true
  });

  refreshSummary = () => {
    sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.RefreshConsignmentProcessSummary', <IE3RefreshConsignmentProcessSummary>{
      consignmentProcessId: this._consignment.consignmentProcessId
    }, (summary: IE3ConsignmentProcessSummary) => {
      this.consignment = summary;
      AppContext.registerSuccess('Consignment summary has been rebuild successfully.');
    });
  }

  private getSubItems = () => this._consignment.masterConsignment.equipments;

  validSelector = (input: string): string => "SELECTOR_"+input.replace(/[^a-zA-Z0-9]/g, '');

  getEnsStatus = (): DeclarationMessageStatus => {
    const status = this._consignment.status;
    return {
      name: "Entry Summary Declaration",
      taskStatus: this._consignment.ensStatus,
      messages: this.getMessagesEns(this._consignment.ensStatus, status),
      customLabel: this.getCustomEnsLabel()
    }
  }

  getTemporaryStorageStatus = (): DeclarationMessageStatus => {
    return {
      name: "Temporary storage",
      taskStatus: this._consignment.temporaryStorageStatus,
      messages: this._consignment.temporaryStorageStatus === TaskMessageStatus.REJECTED
        ? this._consignment.status?.filingStatusTemporaryStorage?.registrationResponse?.errors?.map(
          e => `${e.description}${e.code ? ' ('+e.code +')' : ''}`)
        : []
    }
  }

  private getMessagesEns(taskStatus: TaskMessageStatus, status: IE3ConsignmentStatus): string[] {
    if (!status) {
      return [];
    }
    switch (taskStatus) {
      case TaskMessageStatus.REJECTED:
        return status.filingStatusENS.errornotification?.errors?.map(e => e.description);
      case TaskMessageStatus.WARNING:
        return [`Information requested at: ${this.formatDate(status.additionalInformationRequest.documentIssueTime)}`]
      default: return [];
    }
  }

  private getCustomEnsLabel() {
    switch (this._consignment.ensStatus) {
      case TaskMessageStatus.WARNING:
        return "Information requested";
      case TaskMessageStatus.DELIVERED:
        return "ENS is registered";
      case TaskMessageStatus.REJECTED:
        return this._consignment.status.filingStatusENS.lifecycleValidationErrorNotification
          ? "Lifecycle validation error" : null;
      default:
        return null;
    }
  }
}
