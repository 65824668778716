import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Country, IE3CL707, IE3Party} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {ConsignmentUtils} from "../../../consignment.utils";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-consignment-party-details',
  templateUrl: './consignment-party-details.component.html',
  styleUrls: ['./consignment-party-details.component.scss']
})
export class ConsignmentPartyDetailsComponent {
  portVisitUtils = PortvisitUtils;
  utils = ConsignmentUtils;

  @Input() label: string;
  @Input() party: IE3Party;
  @Input() editMode: boolean;
  @Input() required: boolean;

  partyEditMode: boolean;
  backupParty: IE3Party;

  @Output() partyChange: EventEmitter<IE3Party> = new EventEmitter<IE3Party>();

  countryFormatter = (value: Country | string) => typeof value === "string" ? value : value.code;

  get telephone() {
    return this.party.communications.find(c => c.type === IE3CL707.TE) || {};
  }

  get email() {
    return this.party.communications.find(c => c.type === IE3CL707.EM) || {};
  }

  toggleEditMode = () => {
    this.partyEditMode = !this.partyEditMode;
    if (this.partyEditMode) {
      this.backupParty = cloneDeep(this.party);
    }
  }

  cancel = () => {
    this.party = this.backupParty;
    this.toggleEditMode();
  }

  save = () => {
    this.toggleEditMode();
    this.partyChange.emit(this.party);
  }

  newParty = (value: string): IE3Party => {
    const newParty: IE3Party = {
      name: "Add new " + this.label.toLowerCase(),
      address: {},
      communications: []
    };
    newParty["isNewRecord"] = true;
    return newParty;
  }

  onPartyChange = (value: IE3Party) => {
    this.party = value;
    if (value["isNewRecord"]) {
      this.partyEditMode = true;
    }
  }
}
