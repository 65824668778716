import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {ConsignmentUtils} from "../../../consignment.utils";
import {IE3SupplementaryDeclarant} from "@portbase/bezoekschip-service-typescriptmodels";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-consignment-supplementary-declarant',
  templateUrl: './consignment-supplementary-declarant.component.html',
  styleUrls: ['./consignment-supplementary-declarant.component.scss']
})
export class ConsignmentSupplementaryDeclarantComponent implements OnInit {
  portVisitUtils = PortvisitUtils;
  utils = ConsignmentUtils;

  @Input() label: string;
  @Input() supplementaryDeclarant: IE3SupplementaryDeclarant;
  @Input() required: boolean;

  private _editMode: boolean;
  declarantEditMode: boolean;
  backupSupplementaryDeclarant: IE3SupplementaryDeclarant;

  @Output() supplementaryDeclarantChange: EventEmitter<IE3SupplementaryDeclarant> = new EventEmitter<IE3SupplementaryDeclarant>();

  @Input() set editMode(value: boolean) {
    this._editMode = value;
    if (this._editMode) {
      this.supplementaryDeclarant = this.supplementaryDeclarant || this.newSupplementaryDeclarant();
    }
  }

  ngOnInit(): void {
    this.declarantEditMode = this.editMode;
  }

  get editMode() {
    return this._editMode;
  }

  toggleEditMode = () => {
    this.declarantEditMode = !this.declarantEditMode;
    if (this.declarantEditMode) {
      this.backupSupplementaryDeclarant = cloneDeep(this.supplementaryDeclarant);
    }
  }

  cancel = () => {
    this.supplementaryDeclarant = this.backupSupplementaryDeclarant;
    this.toggleEditMode();
  }

  save = () => {
    this.toggleEditMode();
    this.supplementaryDeclarantChange.emit(this.supplementaryDeclarant);
  }

  newSupplementaryDeclarant = (): IE3SupplementaryDeclarant => {
    const newSupplementaryDeclarant: IE3SupplementaryDeclarant = {
      supplementaryFilingType: null,
      identificationNumber: null
    };
    newSupplementaryDeclarant["isNewRecord"] = true;
    return newSupplementaryDeclarant;
  }

  onSupplementaryDeclarantChange = (value: IE3SupplementaryDeclarant) => {
    this.supplementaryDeclarant = value;
    if (value["isNewRecord"]) {
      this.declarantEditMode = true;
    }
  }
}
