<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>

<div class="declaration-block my-3 position-relative">
  <h2>Transit Guarantees</h2>
  <ul class="nav position-absolute end-0" *ngIf="appContext.isTransitEditor()">
    <li class="nav-item">
      <a href="#" class="nav-link mt-2" (click)="$event.preventDefault(); add()">
        <span class="pe-2 fa fa-plus-circle"></span>Add transit guarantee</a>
    </li>
  </ul>
</div>

<div class="row mb-3 me-3 justify-content-between align-items-end align-items-center" *ngIf="appContext.isAdmin()">
  <div class="col-4">
    <app-form-group label="Short name">
      <app-search [ngModel]="filterOrganisationShortName" [searchFunction]="searchOrganisation"
                  (ngModelChange)="setOrganisationName($event)" [resultFormatter]="formatter"
                  placeholder="Find by organisation">
      </app-search>
    </app-form-group>
  </div>
</div>

<app-pagination #pagination [items]="guarantees" class="accordion" id="releaseAccordion">
  <div *ngFor="let value of pagination.getItemsOnPage(); let index = index; trackBy: trackById" class="my-2"
       [ngClass]="{'fieldset disabled' : !appContext.isTransitEditor()}">
    <app-collapse [collapse]="valueTemplate" [showOnLoad]="value.new" [deleteHandle]="value['new'] && remove(value)">
      <div class="row py-1 ps-3 pe-0 align-items-center text-dark">
        <div class="col-3 cut-text">
          <span class="text-center" [ngSwitch]="true">
            <span class="fa fa-fw fa-1x pe-2 me-2 text-portbase-light-blue"
                  *ngSwitchCase="!value.status"></span>
            <span class="fa fa-fw fa-times-circle pe-2 me-2 text-portbase-red"
                  *ngSwitchCase="value.status === 'REJECTED'"
                  title="Customs has rejected balance request"></span>
            <span class="fa fa-fw fa-check-circle pe-2 me-2 text-portbase-green"
                  *ngSwitchCase="value.status === 'ACCEPTED'"></span>
            <span class="fa fa-fw fa-paper-plane pe-2 me-2 text-info"
                  *ngSwitchCase="value.status === 'DECLARED'"></span>
            <span class="fa fa-fw fa-exclamation-triangle text-warning pe-2 me-2"
                  *ngSwitchDefault></span>
          </span>
          {{value.guaranteeData.guaranteeReferenceNumber || value.guaranteeData.otherReference || 'New guarantee'}}
        </div>
        <div class="col-2 d-none d-md-block small text-secondary" *ngIf="appContext.isAdminOrCustoms()">
          <span class="fa fa-fw fa-users"></span>
          <span class="ms-1">{{value.ownerShortName}}</span>
        </div>
        <div class="col-2 small text-secondary cut-text" [title]="'Guarantee type'">
          {{refData.guaranteeTypeFormatterSmall(value.guaranteeData.type)}}
        </div>
        <div class="col-1 small text-secondary" [title]="'Customs amount'">
          {{formatAmount(value.guaranteeData?.customsValue)}}
        </div>
        <div class="col-1 small text-secondary" [title]="'Balance amount'">
          {{formatAmount(value.balance)}}
        </div>
        <div class="col-1 small text-secondary" [title]="'Last balance query'">
          {{value.timestamp | timestamp:'DD MMM [’]YY'}}
        </div>
        <div class="col-2 text-end d-none d-md-block text-dark">
              <span *ngIf="badgeStatus(value) as badge" class="mx-1 text-center">
                <span class="badge rounded-pill align-text-bottom"
                      [ngClass]="'text-bg-' + badge.color">{{badge.text}}</span></span>
        </div>
      </div>
    </app-collapse>

    <ng-template #valueTemplate>
      <div class="collapse">
        <div>
          <div class="mx-3 py-3">
            <app-transit-guarantee [model]="value" [deleteHandle]="remove(value)"></app-transit-guarantee>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</app-pagination>

<div *ngIf="guarantees.length === 0" class="text-center text-muted" style="padding: 20px 0">
  You haven’t added any transit guarantees yet.
</div>
