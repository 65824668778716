import {AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {EventGateway} from '../event-gateway';
import {openConfirmationModalWithCallback} from "../utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../modal/modal-confirm.component";
import lodash from "lodash";

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent implements AfterViewInit, OnDestroy {

  @ViewChild('modal', {static: true}) modal: ElementRef;
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  @ViewChild('stackContainer', {read: ViewContainerRef}) stackContainer: ViewContainerRef;
  private readonly registration: () => void;

  modalOptions: EditModalOptions;
  onHideConfirmation: ConfirmationInfo = {};
  queuedEvent: EditModalEvent;

  constructor(private eventGateway: EventGateway) {
    this.registration = this.eventGateway.registerLocalHandler(this);
  }

  ngAfterViewInit(): void {
    $(this.modal.nativeElement).on('hidden.bs.modal', this.editModalHiddenCallback);
    $(this.modal.nativeElement).on('hide.bs.modal', this.confirmOnHideCallback);
  }

  'openEditModal' = (event: EditModalEvent) => {
    if (this.container.length > 0) {
      if (event.options?.currentToStack) {
        lodash.range(0, this.container.length).forEach(i =>
          this.stackContainer.insert(this.container.get(i), this.stackContainer.length));
      } else {
        this.queuedEvent = event;
        this.closeEditModal();
        return;
      }
    }
    if (event.component instanceof TemplateRef) {
      this.container.createEmbeddedView(event.component);
    } else {
      const dialogRef = this.container.createComponent(<any>event.component);
      dialogRef.instance['data'] = event.data;
      $(dialogRef.location.nativeElement).addClass('modal-content');
    }
    this.onHideConfirmation = {};
    this.modalOptions = event.options || {};
    this.applyStyling(this.modalOptions);
    $(this.modal.nativeElement).modal('show');
    this.queuedEvent = null;
  }

  'closeEditModal' = () => {
    $(this.modal.nativeElement).modal('hide');
  }

  'confirmationModalOpened' = () => {
    if (this.onHideConfirmation && this.modalOptions?.currentToStack && !this.onHideConfirmation.modalOpened) {
      this.onHideConfirmation.modalOpened = true;
      this.closeEditModal();
    }
  }

  'confirmationModalClosed' = (confirmed: boolean) => {
    if (this.onHideConfirmation?.modalOpened) {
      $(this.modal.nativeElement).modal('show');
      this.onHideConfirmation.modalOpened = false;
    }
  }

  ngOnDestroy(): void {
    $(this.modal.nativeElement).modal('hide');
    this.registration();
  }

  private applyStyling = (modalOptions: EditModalOptions) => {
    const dialog = $(this.modal.nativeElement);
    if (modalOptions?.leftToRight) {
      dialog.addClass("left-to-right");
    } else {
      dialog.removeClass("left-to-right");
    }
    dialog.removeClass('dialog-sm').removeClass('dialog-md').removeClass('dialog-auto');
    if (modalOptions?.size) {
      dialog.addClass(`dialog-${modalOptions.size}`);
    }
  }

  toggleOnChange = () => {
    this.onHideConfirmation.changed = true;
  }

  'formDataSaved' = () => {
    this.onHideConfirmation.changed = false;
  }

  'showPreviousInStack' = (options: EditModalStackOptions) => {
    lodash.range(0, Math.min(this.stackContainer.length + 1, options.amount || 1))
      .forEach(i => {
        if (this.stackContainer.length === 0) {
          if (options.endReached) options.endReached();
          return;
        }
        this.container.clear();
        this.container.insert(this.stackContainer.get(this.stackContainer.length - 1), 0);
      });
  }

  private confirmOnHideCallback = (e) => {
    if (this.modalOptions?.warnOnClose && this.onHideConfirmation?.changed && !this.onHideConfirmation.approved && !this.onHideConfirmation.modalOpened) {
      openConfirmationModalWithCallback((confirmed) => {
        this.onHideConfirmation.modalOpened = false;
        if (confirmed) {
          this.onHideConfirmation.approved = true;
          this.queuedEvent = null;
          $(this.modal.nativeElement).modal('hide');
        } else {
          $(this.modal.nativeElement).modal('show');
        }
      }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        title: "You have unsaved changes",
        message: "Your changes have not been saved. Are you sure you want to discard your changes?",
        confirmText: "Discard changes",
        cancelText: "Keep editing"
      }, "static");
      this.onHideConfirmation.modalOpened = true;
      this.closeEditModal();
      e.preventDefault();
      return false;
    }
    return true;
  };

  private editModalHiddenCallback = () => {
    if (!this.onHideConfirmation.modalOpened) {
      this.container.clear();
      this.stackContainer.clear();
    }
    if (this.queuedEvent) {
      this.openEditModal(this.queuedEvent);
    }
  }
}

interface ConfirmationInfo {
  changed?: boolean;
  modalOpened?: boolean;
  approved?: boolean;
}

export interface EditModalEvent {
  component;
  data?: any;
  options?: EditModalOptions;
}

export interface EditModalOptions {
  leftToRight?: boolean;
  size?: 'sm' | 'md' | 'auto';
  warnOnClose?: boolean;
  currentToStack?: boolean;
}

export interface EditModalStackOptions {
  amount?: number;
  endReached?: () => void;
}
