<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>
<div class="mt-3" *ngIf="this.organisationPreferences && (!appContext.isProduction() || appContext.isAdmin() || appContext.isOrganisationAdmin())">
  <div class="declaration-block">
    <div class="row"><div class="col"><h2>Organisation preferences</h2></div><div class="col-auto"><button class="btn btn-info mt-3" style="width: 100px;" (click)="updateOrganisationPreferences()">Save</button></div></div>
  </div>
  <div class="row mt-1" *ngIf="appContext.isOrganisationAdmin()">
    <input class="d-inline-block mx-3" type="checkbox" style="margin-top: 6px;"
           [ngModel]="givenAuthorisations[gsbnShortName] && givenAuthorisations[gsbnShortName].indexOf('CommercialReleaseDeclarant') >= 0"
           (ngModelChange)="updateAuthorisations(gsbnShortName, 'CommercialReleaseDeclarant', $event)">
    <div class="d-inline-block">Allow GSBN to create Commercial Releases on your behalf</div>
  </div>
  <hr>
</div>
<div class="mt-3" *ngIf="this.userPreferences">
  <div class="pb-2 declaration-block">
    <div class="row">
      <div class="col"><h2>User notification management</h2></div>
      <div class="col-auto">
        <button class="btn btn-info mt-3" style="width: 100px;" (click)="updateUserPreferences()">Save</button>
      </div>
    </div>
  </div>

  <app-collapse [collapse]="shipOperatorEmails">
    <div class="p-3 fw-bold text-dark">Vessel declaration emails</div>
  </app-collapse>

  <ng-template #shipOperatorEmails>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>Port Authority has rejected visit declaration</label><input class="form-control"
                                                                                             placeholder="Email"
                                                                                             style="max-width: 30rem"
                                                                                             [(ngModel)]="this.userPreferences.paRejectEmail">
          </app-form-group>
          <app-form-group><label>Port Authority has accepted visit declaration</label><input class="form-control"
                                                                                             placeholder="Email"
                                                                                             style="max-width: 30rem"
                                                                                             [(ngModel)]="this.userPreferences.paAcceptEmail">
          </app-form-group>
          <app-form-group><label>Port Authority has accepted visit cancellation</label><input class="form-control"
                                                                                              placeholder="Email"
                                                                                              style="max-width: 30rem"
                                                                                              [(ngModel)]="this.userPreferences.visitCancelledEmail">
          </app-form-group>
          <app-form-group><label>Customs has rejected expected arrival declaration</label><input class="form-control"
                                                                                                 placeholder="Email"
                                                                                                 style="max-width: 30rem"
                                                                                                 [(ngModel)]="this.userPreferences.customsRejectEmail">
          </app-form-group>
          <app-form-group><label>Customs has accepted arrival visit declaration</label><input class="form-control"
                                                                                              placeholder="Email"
                                                                                              style="max-width: 30rem"
                                                                                              [(ngModel)]="this.userPreferences.customsAcceptEmail">
          </app-form-group>
          <app-form-group><label>Vessel call has arrived</label><input class="form-control"
                                                                       placeholder="Email"
                                                                       style="max-width: 30rem"
                                                                       [(ngModel)]="this.userPreferences.ataAcknowledgedByAuthorities">
          </app-form-group>
          <app-form-group><label>Vessel call has been transferred to you</label><input class="form-control"
                                                                                       placeholder="Email"
                                                                                       style="max-width: 30rem"
                                                                                       [(ngModel)]="this.userPreferences.visitTransferredEmail">
          </app-form-group>
          <app-form-group><label>You have been assigned as Cargo handling agent</label><input class="form-control"
                                                                                              placeholder="Email"
                                                                                              style="max-width: 30rem"
                                                                                              [(ngModel)]="this.userPreferences.assignedAsCargoAgentEmail">
          </app-form-group>
          <app-form-group><label>You have been assigned as Financial declarant</label><input class="form-control"
                                                                                             placeholder="Email"
                                                                                             style="max-width: 30rem"
                                                                                             [(ngModel)]="this.userPreferences.assignedAsFinancialDeclarantEmail">
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse [collapse]="portCallOptimizationNotifications">
    <div class="p-3 fw-bold text-dark">Port Call Optimization</div>
  </app-collapse>

  <ng-template #portCallOptimizationNotifications>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group *ngIf="appContext.showAtaPpaForPortCallOptimization()" >
            <label>Vessel passed the 240 nautical mile geofence, or vessel departed from another port within the geofence, heading towards Roterdam.</label>
            <div class="row">
              <div class="col-md-6">
                <input class="form-control" placeholder="Email" style="max-width: 30rem" [(ngModel)]="this.userPreferences.portCallOptimizationEmail">
              </div>
              <div *ngIf="appContext.isVisitDeclarant()" class="col-md-6">
                <input class="form-control" placeholder="Phone number" pattern="^\+[1-9]\d{10,14}$" title="e.g. +31612345678" style="max-width: 15rem"
                       [(ngModel)]="this.userPreferences.portCallOptimizationSms">
              </div>
            </div>
          </app-form-group>
          <app-form-group *ngIf="showTerminalStatus()"><label>Terminal updates
            <app-info [wide]="true">
              <span class="tooltipContent text-start">
                Notification will be triggered when the Terminal requests a different
                <ul>
                  <li>RTA and/or ETC</li>
                  <li>Mooring</li>
                </ul>
                This only applies to visits that have Terminal planning set to No.
              </span>
            </app-info>
          </label>
            <div class="row">
              <div class="col-md-6">
                <input class="form-control" placeholder="Email" style="max-width: 30rem" [(ngModel)]="this.userPreferences.terminalStatusEmail" >
              </div>
              <div class="col-md-6">
                <input class="form-control" placeholder="Phone number" pattern="^\+[1-9]\d{10,14}$" title="e.g. +31612345678" style="max-width: 15rem"
                       [(ngModel)]="this.userPreferences.terminalStatusSms" >
              </div>
            </div>
          </app-form-group>
          <app-form-group><label>Notifications when Bunkering may cause delay
            <app-info [wide]="true">
              <span class="tooltipContent text-start">
                Notification will be triggered when the Bunker Operator registers an ETC 30 minutes before ship ETD or later
              </span>
            </app-info>
          </label>
            <div class="row">
              <div class="col-md-6">
                <input class="form-control" placeholder="Email" style="max-width: 30rem" [(ngModel)]="this.userPreferences.bunkeringMayCauseDelayAgentEmail" >
              </div>
              <div class="col-md-6">
                <input class="form-control" placeholder="Phone number" pattern="^\+[1-9]\d{10,14}$" title="e.g. +31612345678" style="max-width: 15rem"
                       [(ngModel)]="this.userPreferences.bunkeringMayCauseDelayAgentSms" >
              </div>
            </div>
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse [collapse]="orderEmails">
    <div class="p-3 fw-bold text-dark">Orders</div>
  </app-collapse>

  <ng-template #orderEmails>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>All notifications involving orders
            <app-info [wide]="true">
              <span class="tooltipContent text-start">
                Notification will be triggered upon:
                <ul>
                  <li>The incoming movement of the vessel has been automatically ordered</li>
                  <li>The order of the movement has been confirmed</li>
                  <li>The order of the movement has been rejected</li>
                  <li>The Port Authority has made remarks about the movement of the vessel</li>
                  <li>The Port Authority has set the order of the movement to 'On Hold'</li>
                </ul>
              </span>
            </app-info>
          </label>
            <div class="row">
              <div class="col-md-6">
                <input class="form-control" placeholder="Email" style="max-width: 30rem"
                       [(ngModel)]="this.userPreferences.orderEmail">
              </div>
              <div class="col-md-6">
                <input class="form-control" placeholder="Phone number" pattern="^\+[1-9]\d{10,14}$"
                       title="e.g. +31612345678" style="max-width: 15rem" [(ngModel)]="this.userPreferences.orderSms">
              </div>
            </div>
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse [collapse]="tidalNotifications">
    <div class="p-3 fw-bold text-dark">Tidal windows</div>
  </app-collapse>

  <ng-template #tidalNotifications>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>All notifications involving tidal windows
            <app-info [wide]="true">
              <span class="tooltipContent text-start">
                Notification will be triggered upon:
                <ul>
                  <li>New (or update on) tidal window determined by HCC</li>
                  <li>New (or update on) tidal window that causes the need to change the ETA/ETD</li>
                </ul>
              </span>
            </app-info>
          </label>
            <div class="row">
              <div class="col-md-6">
                <input class="form-control" placeholder="Email" style="max-width: 30rem"
                       [(ngModel)]="this.userPreferences.tidalWindowsEmail">
              </div>
              <div class="col-md-6">
                <input class="form-control" placeholder="Phone number" pattern="^\+[1-9]\d{10,14}$"
                       title="e.g. +31612345678" style="max-width: 15rem" [(ngModel)]="this.userPreferences.tidalWindowsSms">
              </div>
            </div>
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse [collapse]="commercialReleaseNotifications">
    <div class="p-3 fw-bold text-dark">Commercial releases</div>
  </app-collapse>

  <ng-template #commercialReleaseNotifications>
    <div class="collapse">
      <div class="declaration-block px-3">
        <h2>Default email settings</h2>
      </div>
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group label="Organisation was registered as one of your release-to parties">
            <input class="form-control" placeholder="Email" style="max-width: 30rem"
                   [(ngModel)]="this.userPreferences.carrierCustomerRegistrationEmail">
          </app-form-group>
        </div>
      </div>
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group label="Receive Commercial Release rejection email">
            <input class="form-control" placeholder="Email" style="max-width: 30rem"
                   [(ngModel)]="this.userPreferences.commercialReleaseRejectionEmail">
          </app-form-group>
        </div>
      </div>
      <div class="px-3">
        <app-commercial-release-email-preferences [userPreferences]="this.userPreferences"></app-commercial-release-email-preferences>
      </div>
    </div>
  </ng-template>

  <app-collapse *ngIf="!appContext.isClearancesActive()" [collapse]="mdohEmails">
    <div class="p-3 fw-bold text-dark">Maritime Declaration of Health</div>
  </app-collapse>

  <ng-template #mdohEmails>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>Notifications involving assesment by Port Authority
            <app-info [wide]="true">
              <span class="tooltipContent text-start">
                Notification will be triggered upon:
                <ul>
                  <li>Maritime declaration of health has been approved by Port Authority (with measures imposed)</li>
                  <li>Maritime declaration of health has been rejected by Port Authority</li>
                </ul>
              </span>
            </app-info>
          </label>
            <div class="row">
              <div class="col-md-6">
                <input class="form-control" placeholder="Email" style="max-width: 30rem" [(ngModel)]="this.userPreferences.healthEmail">
              </div>
              <div class="col-md-6">
                <input class="form-control" placeholder="Phone number" pattern="^\+[1-9]\d{10,14}$" title="e.g. +31612345678" style="max-width: 15rem" [(ngModel)]="this.userPreferences.healthSms">
              </div>
            </div>
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse *ngIf="appContext.isClearancesActive()" [collapse]="clearanceNotifications">
    <div class="p-3 fw-bold text-dark">Clearance by Port Authority</div>
  </app-collapse>

  <ng-template #clearanceNotifications>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>Notifications involving clearances by Port Authority
            <app-info [wide]="true">
              <span class="tooltipContent text-start">
                Notification will be triggered upon:
                <ul>
                  <li>Clearance has been approved by Port Authority (with remarks imposed)</li>
                  <li>Clearance has been rejected by Port Authority</li>
                </ul>
              </span>
            </app-info>
          </label>
            <div class="row">
              <div class="col-md-6">
                <input class="form-control" placeholder="Email" style="max-width: 30rem" [(ngModel)]="this.userPreferences.clearanceEmail">
              </div>
              <div class="col-md-6">
                <input class="form-control" placeholder="Phone number" pattern="^\+[1-9]\d{10,14}$" title="e.g. +31612345678" style="max-width: 15rem" [(ngModel)]="this.userPreferences.clearanceSms">
              </div>
            </div>
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse [collapse]="dangerousGoodsEmails">
    <div class="p-3 fw-bold text-dark">Dangerous goods emails</div>
  </app-collapse>

  <ng-template #dangerousGoodsEmails>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>Cargo handling agent has completed dangerous cargo declaration</label><input
            class="form-control" placeholder="Email" style="max-width: 30rem"
            [(ngModel)]="this.userPreferences.dangerousGoodsCompletedEmail"></app-form-group>
          <app-form-group><label>Port authority has accepted dangerous cargo declaration</label><input
            class="form-control" placeholder="Email" style="max-width: 30rem"
            [(ngModel)]="this.userPreferences.dangerousGoodsAcceptedEmail"></app-form-group>
          <app-form-group><label>Port authority has rejected dangerous cargo declaration</label><input
            class="form-control" placeholder="Email" style="max-width: 30rem"
            [(ngModel)]="this.userPreferences.dangerousGoodsRejectedEmail"></app-form-group>
          <app-form-group><label>Dangerous goods message (IFTDGN) could not be processed</label><input
            class="form-control" placeholder="Email" style="max-width: 30rem"
            [(ngModel)]="this.userPreferences.iftdgnRejectedEmail"></app-form-group>
        </div>
      </div>
    </div>
  </ng-template>


  <app-collapse [collapse]="cargoAgentEmails">
    <div class="p-3 fw-bold text-dark">Cargo import emails for cargo handling agents</div>
  </app-collapse>

  <ng-template #cargoAgentEmails>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>Customs have rejected ATO declaration</label><input class="form-control"
                                                                                     placeholder="Email"
                                                                                     style="max-width: 30rem"
                                                                                     [(ngModel)]="this.userPreferences.cargoImportRejectedEmail">
          </app-form-group>
          <app-form-group><label>Customs have accepted ENS declaration</label><input class="form-control"
                                                                                     placeholder="Email"
                                                                                     style="max-width: 30rem"
                                                                                     [(ngModel)]="this.userPreferences.ensAcceptedEmail">
          </app-form-group>
          <app-form-group><label>Customs have rejected ENS declaration</label><input class="form-control"
                                                                                     placeholder="Email"
                                                                                     style="max-width: 30rem"
                                                                                     [(ngModel)]="this.userPreferences.ensRejectedEmail">
          </app-form-group>
          <app-form-group><label>Cargo import message (IFTMCS) could not be processed</label><input class="form-control"
                                                                                                    placeholder="Email"
                                                                                                    style="max-width: 30rem"
                                                                                                    [(ngModel)]="this.userPreferences.iftmcsRejectedEmail">
          </app-form-group>
          <app-form-group><label>Cargo import message (IFTMCS) discharge locations differ from those reported by the
            ship operator. Your discharge locations have been changed</label><input class="form-control"
                                                                                    placeholder="Email"
                                                                                    style="max-width: 30rem"
                                                                                    [(ngModel)]="this.userPreferences.terminalDifferenceEmail">
          </app-form-group>
          <app-form-group><label>Ship operator has changed a discharge location of your cargo</label><input
            class="form-control" placeholder="Email" style="max-width: 30rem"
            [(ngModel)]="this.userPreferences.terminalChangedByShipOperatorEmail"></app-form-group>
          <app-form-group><label>Ship operator has removed a discharge location of your cargo</label><input
            class="form-control" placeholder="Email" style="max-width: 30rem"
            [(ngModel)]="this.userPreferences.terminalDeletedByShipOperatorEmail"></app-form-group>
          <app-form-group><label>Clearance differences reported by customs</label><input class="form-control"
                                                                                         placeholder="Email"
                                                                                         style="max-width: 30rem"
                                                                                         [(ngModel)]="this.userPreferences.clearanceDifferencesEmail">
          </app-form-group>
          <app-form-group><label>Clearance is written off by customs</label><input class="form-control"
                                                                                   placeholder="Email"
                                                                                   style="max-width: 30rem"
                                                                                   [(ngModel)]="this.userPreferences.clearanceClosureEmail">
          </app-form-group>
          <app-form-group><label>Bulk discharge differences reported by terminal</label><input class="form-control"
                                                                                   placeholder="Email"
                                                                                   style="max-width: 30rem"
                                                                                   [(ngModel)]="$any(this.userPreferences).bulkDischargeEmail">
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse [collapse]="cargoImportShipOperatorEmails">
    <div class="p-3 fw-bold text-dark">Cargo import emails for ship operators</div>
  </app-collapse>

  <ng-template #cargoImportShipOperatorEmails>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>Different berth locations reported by cargo handling agent</label><input
            class="form-control" placeholder="Email" style="max-width: 30rem"
            [(ngModel)]="this.userPreferences.terminalDifferenceForShipOperatorEmail"></app-form-group>
        </div>
      </div>
    </div>
  </ng-template>

  <app-collapse [collapse]="cargoTerminalEmails" *ngIf="appContext.isCargoTerminal() || appContext.isAdmin()">
    <div class="p-3 fw-bold text-dark">Cargo import emails for terminals</div>
  </app-collapse>

  <ng-template #cargoTerminalEmails>
    <div class="collapse">
      <div class="p-3 row mt-1">
        <div class="col-md-12">
          <app-form-group><label>Reported containers have been refused</label><input class="form-control"
                                                                                     placeholder="Email"
                                                                                     style="max-width: 30rem"
                                                                                     [(ngModel)]="this.userPreferences.containerRefusedEmail">
          </app-form-group>
        </div>
      </div>
    </div>
  </ng-template>
</div>
