import {Component} from '@angular/core';
import {VisitOverviewServiceStatusComponent} from "../visit-overview-service-status.component";
import {DeclarationType, TaskMessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";
import {DeclarationMessageStatus, VisitOverviewUtils} from "../../visit-overview.utils";

@Component({
  selector: 'app-terminal-visit-overview-service-status',
  templateUrl: './terminal-visit-overview-service-status.component.html',
  styleUrls: ['./terminal-visit-overview-service-status.component.scss', '../visit-overview-service-status.component.scss']
})
export class TerminalVisitOverviewServiceStatusComponent extends VisitOverviewServiceStatusComponent {
  _declarationTypes = [DeclarationType.TERMINAL_PLANNING];
  terminalMessageStatuses: DeclarationMessageStatus[] = [];
  cargoHandlingStatus: DeclarationMessageStatus;

  updateMessageStatus() {
    this.cargoHandlingStatus = VisitOverviewUtils.getCargoHandlingStatus(this._summary);
    this.terminalMessageStatuses = VisitOverviewUtils.sortTaskMessageStatuses(VisitOverviewUtils.getTaskStatuses(this._summary, this._declarationTypes, this.clearanceService)).reverse();
    this.messageStatus = VisitOverviewUtils.getLeastTaskMessageStatus(this.terminalMessageStatuses.concat(this.cargoHandlingStatus));
  }

  get enabledTerminalMessageStatuses() {
    return this.terminalMessageStatuses.filter(s => s.taskStatus !== TaskMessageStatus.DISABLED);
  }

  get terminalMessageStatusEnabled() {
    return this.enabledTerminalMessageStatuses.length > 0;
  }
}
