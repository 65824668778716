<ng-container *ngIf="equipment">
  <div class="modal-header">
    <div class="flex-container align-items-center">
      <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="d-inline-flex me-1">
        <i class="fa fa-fw fa-light fa-lg fa-file-lines text-muted"></i>
      </div>
      <button class="btn btn-link p-0 page-title align-baseline" (click)="openMasterConsignment()">
        <span class="page-title">{{ consignmentProcess.consignmentMasterLevel.consignmentNumber }}</span>
      </button>
      <app-tooltip *ngIf="consignmentProcess.cancelled" placement="bottom" class="ms-2">
        <span class="fa text-danger fa-fw fa-ban"></span>
        <span class="tooltipContent">Cancelled</span>
      </app-tooltip>
      <div class="d-inline-flex mx-2">
        <i class="fa fa-fw fa-light fa-lg fa-chevron-right text-muted"></i>
      </div>
      <ng-container *ngIf="houseConsignment">
        <div class="d-inline-flex me-1">
          <i class="fa fa-kit fa-fw fa-light fa-lg fa-pb-file-line text-muted"></i>
        </div>
        <button class="btn btn-link p-0 page-title align-baseline" (click)="openHouseConsignment()">
          <span class="page-title">{{ houseConsignment.consignmentNumber }}</span>
        </button>
        <div class="d-inline-flex mx-2">
          <i class="fa fa-fw fa-light fa-lg fa-chevron-right text-muted"></i>
        </div>
      </ng-container>
      <div class="d-inline-flex me-1">
        <i class="fa fa-fw fa-light fa-lg text-muted" [ngClass]="utils.getEquipmentIconCls(equipment.containerSizeAndType, equipment.empty)"></i>
      </div>
      <span class="page-title">{{ equipment.containerIdentificationNumber }}</span>
    </div>
  </div>

  <div class="modal-body p-0">
    <app-tab-panel [sticky]="true" [alwaysRender]="true">
      <app-tab-item [templateRef]="detailsRef">
        <span class="tab-item">Details</span>
        <ng-template #detailsRef>
          <div class="container py-3">
            <div class="card">
              <div class="card-body p-5">
                <div class="row gy-4 my-0">
                  <h5 class="h1 col-12 m-0">Equipment details</h5>
                  <div class="col-12">
                    <div class="row gy-4">
                      <app-form-field label="Equipment number" class="col-6 col-md-3"
                                      [value]="equipment.containerIdentificationNumber"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false">
                        <ng-container *ngIf="isNewEquipment else blAsLabel">
                          <input class="input-group rounded border form-control"
                                 [(ngModel)]="equipment.containerIdentificationNumber" required/>
                        </ng-container>
                        <ng-template #blAsLabel>
                          <span>{{ equipment.containerIdentificationNumber }}</span>
                        </ng-template>
                      </app-form-field>
                      <div class="col-auto">
                        <app-form-field label="Empty" class="col-6 col-md-3"
                                        [value]="equipment.empty" [formatter]="emptyIndicatorFormatter"
                                        [includeMargin]="false" [editMode]="false" [renderOptional]="false">
                          <app-yes-no [(ngModel)]="equipment.empty"></app-yes-no>
                        </app-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Size type" class="col-12 col-md-6"
                                      [value]="equipment.containerSizeAndType" [editMode]="editMode"
                                      [formatter]="utils.sizeTypeFormatter" [includeMargin]="false">
                        <app-select [(ngModel)]="equipment.containerSizeAndType" dataKey="name" required
                                    [options]="utils.sizeTypeOptions" [formatter]="utils.sizeTypeFormatter"
                                    [selectedFormatter]="utils.sizeTypeFormatter"></app-select>
                      </app-form-field>
                      <app-form-field label="Container operator" class="col-12 col-md-6"
                                      [value]="equipment.containerOperator" [editMode]="editMode"
                                      [includeMargin]="false" [formatter]="utils.carrierFormatter">
                        <app-search [(ngModel)]="equipment.containerOperator" [required]="isDutchPort"
                                    [searchFunction]="utils.findCarriers" [resultFormatter]="utils.carrierFormatter"
                                    [inputFormatter]="utils.carrierFormatter">
                        </app-search>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Supplier" class="col-12"
                                      [value]="equipment.containerSupplierType" [editMode]="editMode"
                                      [formatter]="utils.supplierTypeFormatter" [includeMargin]="false">
                        <app-select [(ngModel)]="equipment.containerSupplierType" [required]="utils.houseConsignmentSupported(consignmentProcess)"
                                    [options]="utils.supplierTypes" [formatter]="utils.supplierTypeFormatter"
                                    [selectedFormatter]="utils.supplierTypeFormatter"></app-select>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Oversized" class="col-12 col-md-6"
                                      [value]="oversize" [includeMargin]="false" [editMode]="editMode">
                        <app-yes-no [(ngModel)]="oversize"></app-yes-no>
                      </app-form-field>
                    </div>
                  </div>
                  <ng-container *ngIf="oversize">
                    <div class="col-12">
                      <div class="row">
                        <app-form-field label="Overlength (m)" class="col-12 col-md-2" [renderOptional]="false"
                                        [value]="equipment.oversize.overlength" [includeMargin]="false" [editMode]="editMode">
                          <input class="input-group rounded border form-control" type="number"
                                 [(ngModel)]="equipment.oversize.overlength"/>
                        </app-form-field>
                        <app-form-field label="Overwidth (m)" class="col-12 col-md-2" [renderOptional]="false"
                                        [value]="equipment.oversize.overwidth" [includeMargin]="false" [editMode]="editMode">
                          <input class="input-group rounded border form-control" type="number"
                                 [(ngModel)]="equipment.oversize.overwidth"/>
                        </app-form-field>
                        <app-form-field label="Overheight (m)" class="col-12 col-md-2" [renderOptional]="false"
                                        [value]="equipment.oversize.overheight" [includeMargin]="false" [editMode]="editMode">
                          <input class="input-group rounded border form-control" type="number"
                                 [(ngModel)]="equipment.oversize.overheight"/>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <app-form-field label="Oversize remarks" class="col-12 col-md-5" [renderOptional]="true"
                                        [value]="equipment.oversize.remarks" [includeMargin]="false" [editMode]="editMode">
                          <input class="input-group rounded border form-control"
                                 [(ngModel)]="equipment.oversize.remarks"/>
                        </app-form-field>
                      </div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Tare weight (kg)" class="col-6 col-md-2"
                                      [value]="equipment.tareWeight" [editMode]="editMode"
                                      [includeMargin]="false">
                        <input class="input-group rounded border form-control" type="number"
                               [(ngModel)]="equipment.tareWeight"/>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Temperature (°C)" class="col col-md-2"
                                      [value]="equipment.temperature" [editMode]="editMode" [includeMargin]="false">
                        <input class="input-group rounded border form-control" type="number"
                               [(ngModel)]="equipment.temperature"/>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Seal identifier" class="col-6 col-md-7"
                                      [value]="equipment.sealIdentifiers" [editMode]="editMode"
                                      [formatter]="sealIdentifiersFormatter" [includeMargin]="false">
                        <app-tags [(ngModel)]="equipment.sealIdentifiers" placeholder="Add Seal" [readonly]="!editMode"
                                  class="input-group rounded border form-control"></app-tags>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Redelivery address" class="col col-md-5"
                                      [value]="equipment.redeliveryAddress" [editMode]="editMode"
                                      [includeMargin]="false">
                        <input class="input-group rounded border form-control"
                               [(ngModel)]="equipment.redeliveryAddress"/>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <app-form-field label="Booking reference" class="col-12 col-md-5" [renderOptional]="true"
                                      [value]="equipment.bookingReference" [includeMargin]="false" [editMode]="editMode">
                        <input class="input-group rounded border form-control"
                               [(ngModel)]="equipment.bookingReference"/>
                      </app-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="goodsRef">
        <span class="tab-item">Goods</span>
        <ng-template #goodsRef>
          <app-consignment-goods-table [data]="goodsItems" [consignmentProcess]="consignmentProcess"
                                       [houseConsignmentNumber]="houseConsignment?.consignmentNumber"
                                       [equipmentNumber]="equipment.containerIdentificationNumber"></app-consignment-goods-table>
        </ng-template>
      </app-tab-item>
    </app-tab-panel>
  </div>

  <div class="modal-footer" *ngIf="!consignmentProcess.cancelled">
    <div class="d-flex flex-grow-1">
      <div class="container">
        <div class="row mx-4">
          <div class="col-auto">
            <button type="button" class="btn btn-outline-danger" (click)="remove()">Remove</button>
          </div>
          <div class="col-auto ms-auto">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close"
                    *ngIf="!editMode">
              Close
            </button>
            <button type="button" class="btn btn-outline-secondary" *ngIf="editMode" (click)="toggleEdit()">
              Cancel
            </button>
          </div>
          <div class="col-auto">
            <ng-container *ngIf="editMode else readMode">
              <button type="button" class="btn btn-primary me-4" (click)="save()">Save</button>
            </ng-container>
            <ng-template #readMode>
              <button *ngIf="isEditable" type="button" class="btn btn-outline-secondary me-4" (click)="toggleEdit()">Edit</button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
