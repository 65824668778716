<div class="my-3"><i>Updates the sizetypes in both bezoekschip and hinterland</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="sizetype['id']" disabled required></app-form-group>
    <app-form-group><label>Code</label><input class="form-control" [(ngModel)]="sizetype.code" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="sizetype.name" ></app-form-group>
  </div>
</div>
