import {Component, ElementRef, Input} from '@angular/core';
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {
  Guarantee,
  RequestTransitGuaranteeBalance,
  SaveTransitGuarantee
} from "@portbase/bezoekschip-service-typescriptmodels";
import {QueryGateway} from "../../../common/query-gateway";
import {checkValidity, sendCommand} from "../../../common/utils";
import {AppContext} from "../../../app-context";
import moment from "moment";

@Component({
  selector: 'app-transit-guarantee',
  templateUrl: './transit-guarantee.component.html',
  styleUrls: ['./transit-guarantee.component.css']
})

export class TransitGuaranteeComponent {
  refData = PortvisitUtils;
  appContext = AppContext;
  @Input() model: Guarantee;
  @Input() deleteHandle : () => any;

  constructor(private queryGateway: QueryGateway, public elementRef: ElementRef) {
  }

  delete() {
    this.deleteHandle();
  }

  save() {
    if (checkValidity(this.elementRef)) {
      sendCommand('com.portbase.bezoekschip.common.api.transit.SaveTransitGuarantee', <SaveTransitGuarantee> {
        id: this.model.id,
        ownerShortName: this.model.ownerShortName,
        guaranteeData: this.model.guaranteeData
      }, () => {
        AppContext.registerSuccess('The guarantee was saved successfully.');
        delete this.model['new'];
        this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.transit.FindGuarantees");
      });
    }
  }

  requestBalance() {
    sendCommand('com.portbase.bezoekschip.common.api.transit.RequestTransitGuaranteeBalance', <RequestTransitGuaranteeBalance>{
      id: this.model.id,
    }, () => {
      AppContext.registerSuccess('The request for balance was send successfully.');
      this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.transit.FindGuarantees");
    })
  }

  get expired() : boolean {
    let dateFrom = this.model.guaranteeData?.validFrom ? Date.parse(this.model.guaranteeData?.validFrom) : 0;
    let dateTo = this.model.guaranteeData?.validTo ? Date.parse(this.model.guaranteeData?.validTo) : Number.MAX_VALUE;
    let now = Date.now().valueOf();
    return !(dateFrom <= now && now <= dateTo);
  };

  get lastQueryDateIsToday() : boolean {
    return moment().isSame(this.model.timestamp, 'date');
  }

  get sendable() : boolean {
    return !this.model['new'] && sendableTypes.indexOf(this.model.guaranteeData.type?.code) >= 0;
  }

  get requiresGrn() : boolean {
    return grnTypes.indexOf(this.model.guaranteeData.type?.code) >= 0;
  }
}

const sendableTypes : string[] = ["0", "1", "9"];
const grnTypes : string[] = ["0", "1", "2", "4", "9"];
