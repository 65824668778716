import {Component, Input} from '@angular/core';
import {SizeType} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-sizetype',
  templateUrl: './edit-sizetype.component.html',
  styleUrls: ['./edit-sizetype.component.css']
})
export class EditSizeTypeComponent {
  @Input() sizetype : SizeType;
}
