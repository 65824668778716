import {AfterViewInit, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3EquipmentSummary,
  IE3HouseConsignment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {TableField} from "../../../../../common/table/table-view/table-view.component";
import {openEditModal} from "../../../../../common/utils";
import {
  HouseConsignmentDetailsComponent,
  HouseConsignmentDetailsComponentData
} from "../../house-consignment-details/house-consignment-details.component";
import {ConsignmentUtils} from "../../../consignment.utils";
import {
  EquipmentConsignmentDetailsComponent,
  EquipmentConsignmentDetailsComponentData
} from "../../equipment-consignment-details/equipment-consignment-details.component";

@Component({
  selector: 'app-house-consignments-table',
  templateUrl: './house-consignments-table.component.html',
  styleUrls: ['./house-consignments-table.component.scss']
})
export class HouseConsignmentsTableComponent implements AfterViewInit {
  utils = ConsignmentUtils;

  @Input() consignmentProcess: IE3ConsignmentProcess;
  @Input() term: string;
  @Input() data: IE3HouseConsignment[];
  @Input() editable: boolean = true;
  fieldsDefinition: TableField[] = [];

  @ViewChild("equipmentRef") equipmentRef: TemplateRef<any>;
  @ViewChild("goodsRef") goodsRef: TemplateRef<any>;
  @ViewChild("actionColumnRef") actionColumnRef: TemplateRef<any>;
  @ViewChild("houseConsignmentRef") houseConsignmentRef: TemplateRef<any>;

  ngAfterViewInit(): void {
    this.fieldsDefinition = [
      {
        type: "string",
        name: "consignmentNumber",
        header: "House BL",
        template: this.houseConsignmentRef
      }, {
        type: "string",
        header: "Equipment",
        template: this.equipmentRef
      }, {
        type: "string",
        name: "goodsItems",
        header: "Goods",
        template: this.goodsRef
      }, {
        type: "action",
        name: "actionColumn",
        template: this.actionColumnRef,
        cellClass: "p-1 auto-width"
      }
    ]
  }

  trackByForRow = (index: number, record: IE3ConsignmentHouseLevel) => record.consignmentNumber;

  trackByEquipmentNumber = (index: number, record: IE3EquipmentSummary) => record.equipmentNumber;

  edit = (record: IE3ConsignmentHouseLevel) => openEditModal(HouseConsignmentDetailsComponent, <HouseConsignmentDetailsComponentData>{
    consignmentProcessId: this.consignmentProcess.consignmentProcessId,
    consignmentNumber: record?.consignmentNumber,
    cachedConsignmentProcess: this.consignmentProcess
  }, {
    warnOnClose: true,
    currentToStack: true
  });

  editEquipment = (equipmentNumber: string, houseConsignmentNumber: string) => openEditModal(EquipmentConsignmentDetailsComponent, <EquipmentConsignmentDetailsComponentData>{
    consignmentProcessId: this.consignmentProcess.consignmentProcessId,
    equipmentNumber: equipmentNumber,
    cachedConsignmentProcess: this.consignmentProcess,
    houseConsignmentNumber: houseConsignmentNumber
  }, {
    warnOnClose: true,
    currentToStack: true
  });
}
