import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import moment from "moment";
import {
  IE3ContainerProcessSummary
} from "@portbase/bezoekschip-service-typescriptmodels";
import {ContainerUtils} from "../../container.utils";
import {CheckboxSelectionState, openEditModal} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../../../consignments/details/master-consignment-details/master-consignment-details.component";
import {
  EquipmentConsignmentDetailsComponent,
  EquipmentConsignmentDetailsComponentData
} from "../../../consignments/details/equipment-consignment-details/equipment-consignment-details.component";
import {ConsignmentUtils} from "../../../consignments/consignment.utils";

@Component({
  selector: 'app-container-overview-item',
  templateUrl: './container-overview-item.component.html',
  styleUrls: ['./container-overview-item.component.scss']
})
export class ContainerOverviewItemComponent {
  utils = ContainerUtils;
  appContext = AppContext;

  @Input() container: IE3ContainerProcessSummary;
  @Input() term: string;
  @Input() depth: number;
  @Input() collapsible: boolean;

  selectionWidth: number = 40;
  paddingPerStep: number = 20;
  collapsed: boolean = true;

  formatDate = (dateString: string): string => !!dateString ? moment(dateString).format("DD MMM HH:mm") : null;

  get selectable() {
    return this.container.consignmentDataSummaries.length == 0;
  }

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  get selectionState(): CheckboxSelectionState {
    return this.container["selected"];
  }

  set selectionState(value: CheckboxSelectionState) {
    this.container["selected"] = value;
  }

  validSelector = (input: string): string => "SELECTOR_"+input.replace(/[^a-zA-Z0-9]/g, '');
}
