<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-auto px-3 py-4 d-none d-md-block border-end filters mh-100" [style.width.rem]="17">
      <ng-container *ngTemplateOutlet="filtersRef"></ng-container>
    </div>
    <div class="col mh-100 d-flex flex-column align-items-stretch">
      <div class="row bg-light px-3 py-2 border-bottom" [style.height.px]="57">
        <div class="col-auto d-flex d-md-none px-1">
          <button class="btn text-primary p-0 btn-close-wrapper rounded-circle" (click)="openFiltersInModal()"
                  [style.width.px]="42" [ngClass]="facetUtils.hasActiveFilters(overviewFilters) ? 'active': ''">
            <span class="fa-light fa-filter fa-fw m-0 h4"></span>
          </button>
        </div>
        <div class="col-auto d-flex d-md-none px-1">
          <button onPopover class="btn text-primary p-0 btn-close-wrapper rounded-circle"
                  [style.width.px]="42" [ngClass]="term ? 'active': ''"
                  data-bs-toggle="popover" [popoverContent]="termPopover" [placement]="'bottom'"
                  popoverClass="pop-container-small position-fixed">
            <span class="fa-light fa-search fa-fw m-0 h4"></span>
          </button>
        </div>
        <div class="col col-lg-6 d-none d-md-flex align-items-center" style="max-width: 320px;">
          <app-filter [(ngModel)]="term" [minCharacters]="3"
                      (ngModelChange)="from = 0; endReached = false; termChanged.emit($event)"
                      [searchFunction]="searchFunction" (resultsFound)="resultsFound.emit($event)"
                      placeholder="Search"></app-filter>
        </div>
        <div class="col-auto ms-auto d-flex flex-row align-items-center">
          <ng-content select=".header-bar"></ng-content>
        </div>
      </div>
      <div class="row flex-grow-1" style="height: 0">
        <app-scroller (endReached)="loadNextPage.emit()" class="col-12 px-0 mh-100">
          <ng-content select=".overview-items"></ng-content>
        </app-scroller>
        <div class="w-100 h-100" [ngClass]="{'d-none': !isEmpty}">
          <ng-content select=".empty-container"></ng-content>
        </div>
      </div>
      <ng-content select=".overview-footer"></ng-content>
    </div>
  </div>
</div>

<div id="filtersModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="custom-modal-content">
      <ng-content select=".modal-content"></ng-content>
    </div>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Remove item</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngTemplateOutlet="filtersRef"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #termPopover>
  <div class="row">
    <div class="col-12">
      <app-filter [(ngModel)]="term" [minCharacters]="3"
                  (ngModelChange)="from = 0; endReached = false; termChanged.emit($event)"
                  [searchFunction]="searchFunction" (resultsFound)="resultsFound.emit($event)"
                  placeholder="Search"></app-filter>
    </div>
  </div>
</ng-template>

<ng-template #filtersInModal>
  <div class="modal-content">
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="filtersRef"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #filtersRef>
  <div class="position-relative">
    <ng-container *ngIf="!!quickViewsTemplate">
      <label class="mx-3 sub-text text-uppercase d-inline-flex">Quick views</label>
      <div class="btn-close-wrapper d-md-none rounded-circle position-absolute end-0 me-2" style="top: -8px">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </ng-container>
    <ng-container *ngTemplateOutlet="quickViewsTemplate"></ng-container>
    <ng-container *ngIf="facets">
      <app-facets-overview [facets]="facets" [otherFacets]="otherFacets" [overviewFilters]="overviewFilters"
                           [specificFacetFiltersTemplate]="specificFacetFiltersTemplate"
                           [facetNameFormatter]="facetNameFormatter" (filtersCleared)="filtersCleared.emit($event)"
                           (facetFiltersChanged)="onFacetValuesChange($event)"></app-facets-overview>
    </ng-container>
  </div>
</ng-template>
