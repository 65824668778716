<ng-container *ngIf="portEntry">
  <div class="fieldset" [ngClass]="{'disabled' :firstBerthVisit.ata || firstBerthVisit.atd}">
    <div class="row" *ngIf="!portEntry.entryDependency?.vesselName">
      <div class="col">
        <app-form-group *ngIf="hasFirstBerth() && portEntry.origin === 'SEA'">
          <label for="select-dependency">Dependency on vessel</label>
          <app-select id="select-dependency" [(ngModel)]="dependencyType" [options]="dependencyOptions"
            [formatter]="dependencyFormatter" [refreshWatch]="firstBerthVisit.berth.berthGroupCode"
            (ngModelChange)="resolveDependency($event)">
          </app-select>
        </app-form-group>
      </div>
    </div>
    <div class="row" *ngIf="portEntry.origin === 'SEA' && portEntry.entryDependency">
      <div class="declaration-block col-md-12">
        <app-form-group
          *ngIf="portEntry.entryDependency.dependencyType === 'ENTRY_AFTER' && !portEntry.entryDependency.vesselName">
          <app-info class="formGroupLabel">
            <label for="entry-after-vessel">Select vessel</label>
            <span class="tooltipContent">Only vessels that are already arrived or will arrive within 48 hours of the
              chosen ETA pilot boarding place will be shown.
              Vessels that have already been selected by different agents can not be selected.
              Please contact the terminal if you want to select one of these.</span>
          </app-info>
          <app-search id="entry-after-vessel" [ngModel]="dependentVisit" (ngModelChange)="setEntryDependency($event)"
            required [minCharacters]="0" [searchFunction]="refreshVisitsForEntryAfter" [styling]="styleEntryAfter"
            [inputFormatter]="entryAfterFormatter">
          </app-search>
        </app-form-group>
        <app-form-group
          *ngIf="portEntry.entryDependency.dependencyType === 'EXCHANGE' && !portEntry.entryDependency.vesselName">
          <app-info class="formGroupLabel">
            <label for="entry-after-vessel">Select vessel</label>
            <span class="tooltipContent">Vessels that have already been selected by different agents can not be
              selected.
              Please contact the terminal if you want to select one of these.</span>
          </app-info>
          <app-search id="exchange-vessel" [ngModel]="dependentVisit" (ngModelChange)="setEntryDependency($event)"
            required [minCharacters]="0" [searchFunction]="refreshVisitsForExchange" [styling]="styleExchange"
            [inputFormatter]="exchangeFormatter">
          </app-search>
        </app-form-group>
        <div *ngIf="portEntry.entryDependency && portEntry.entryDependency.vesselName">
          <app-form-group>
            <label *ngIf="portEntry.entryDependency.dependencyType === 'EXCHANGE'">Exchange with</label>
            <label *ngIf="portEntry.entryDependency.dependencyType === 'ENTRY_AFTER'">Entry after</label>
            <div class="card">
              <div class="card-body text-secondary py-1" [ngClass]="{'bg-light': context.isArrived()}">
                <div class="row">
                  <div class="col px-1" *ngIf="appContext.isAdmin()"><a
                      [href]="['/details/' + portEntry.entryDependency.crn]"
                      target="_blank">{{portEntry.entryDependency.vesselName}} - {{portEntry.entryDependency.crn}}</a>
                  </div>
                  <div class="col px-1" *ngIf="!appContext.isAdmin()">{{portEntry.entryDependency.vesselName}}</div>
                  <span class="col-auto pull-right clickable pe-0 me-1" title="Remove" (click)="removeDependencyOnVessel()"
                    [ngClass]="{'disabled' : 'DECLARED' === context.declarationStatusOf(VISIT)}"><i
                      class="fa fa-times"></i></span>
                </div>
                <div class="row" *ngIf="portEntry.entryDependency.berthName">
                  <div>
                    <div class="col px-1">{{portEntry.entryDependency.berthName}} -
                      {{portEntry.entryDependency.berthCode}}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col px-1">
                    {{formatTime(portEntry.entryDependency)}}
                  </div>
                  <div *ngIf="portEntry.entryDependency.movementOnHold" class="col text-end px-1">ON HOLD</div>
                  <div *ngIf="!portEntry.entryDependency.movementOnHold && portEntry.entryDependency.orderStatus"
                    class="col text-end px-1">
                    Order:
                    <span [ngSwitch]="portEntry.entryDependency.orderStatus">
                      <span style="vertical-align: text-bottom;" *ngSwitchCase="'ORDERED'"
                        class="badge bg-portbase-light-blue">
                        <span class="fa fa-paper-plane text-white"></span>
                      </span>
                      <span style="vertical-align: text-bottom;" *ngSwitchCase="'REJECTED'"
                        class="badge bg-portbase-red bg-light">
                        <span class="fa fa-times text-white"></span>
                      </span>
                      <span style="vertical-align: text-bottom;" *ngSwitchCase="'CANCELLED'"
                        class="badge bg-portbase-red bg-light">
                        <span class="fa fa-times text-white"></span>
                      </span>
                      <span style="vertical-align: text-bottom;" *ngSwitchCase="'CONFIRMED'"
                        class="badge bg-portbase-green">
                        <span class="fa fa-check text-white"></span>
                      </span>
                      <span style="vertical-align: text-bottom;" *ngSwitchCase="'ETA_CHANGED'" class="badge text-bg-warning">
                        <span class="fa fa-check text-white"></span>
                      </span>
                      <span style="vertical-align: text-bottom;" *ngSwitchCase="'ETD_CHANGED'" class="badge text-bg-warning">
                        <span class="fa fa-check text-white"></span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </app-form-group>
        </div>
        <app-form-group *ngIf="showAutomaticOrder && portEntry.entryDependency.vesselName">
          <app-info class="formGroupLabel">
            <label>Auto-order</label>
            <span *ngIf="portEntry.entryDependency.dependencyType === 'EXCHANGE'" class="tooltipContent">
              The incoming vessel is automatically ordered when the departing vessel is ordered.</span>
            <span *ngIf="portEntry.entryDependency.dependencyType === 'ENTRY_AFTER'" class="tooltipContent">
              The incoming vessel is automatically ordered when the other arriving vessel is ordered.</span>
          </app-info>
          <app-yes-no required [(ngModel)]="portEntry.entryDependency.autoOrder"
            (ngModelChange)="orderIncomingMovementIfRequired($event)">
          </app-yes-no>
        </app-form-group>
      </div>
    </div>
  </div>
</ng-container>
