<ng-container *ngIf="consignmentProcess">
  <div class="modal-header">
    <div class="flex-container align-items-center">
      <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="d-inline-flex me-1">
        <i class="fa fa-fw fa-light fa-lg fa-file-lines text-muted"></i>
      </div>
      <span class="page-title">{{ consignmentProcess.consignmentMasterLevel.consignmentNumber }}</span>
      <app-tooltip *ngIf="consignmentProcess.cancelled" placement="bottom" class="ms-2">
        <span class="fa text-danger fa-fw fa-ban"></span>
        <span class="tooltipContent">Cancelled</span>
      </app-tooltip>
    </div>
  </div>

  <div class="modal-body p-0" [ngClass]="editMode ? 'edit-mode' : ''">
    <app-tab-panel [sticky]="true" [alwaysRender]="true">
      <app-tab-item [templateRef]="detailsRef">
        <span class="tab-item">Details</span>
        <ng-template #detailsRef>
          <div class="container py-3">
            <div class="card">
              <div class="card-body p-5">
                <div class="row gy-4 my-0">
                  <h5 class="h1 col-12 m-0">Master bill of lading details</h5>
                  <div class="col-12" *ngIf="billOfLadingFilingType">
                    <div class="row gy-4">
                      <app-form-field label="Bill of lading type" class="col-6 col-md-3"
                                      [value]="billOfLadingFilingType.billOfLadingType"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false"
                                      [formatter]="refData.enumFormatter" [tooltipTemplateRef]="billOfLadingTooltip">
                        <app-radio [(ngModel)]="billOfLadingFilingType.billOfLadingType" (ngModelChange)="updateFilingType()"
                                   [inline]="true" [options]="billOfLadingTypes" [formatter]="refData.enumFormatter" [required]="true"></app-radio>
                      </app-form-field>
                      <app-form-field label="Filing" class="col-6 col-md-3"
                                      [value]="billOfLadingFilingType.filing"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false"
                                      [formatter]="refData.enumFormatter" [tooltipTemplateRef]="filingTypeTooltip">
                        <app-radio [(ngModel)]="billOfLadingFilingType.filing" (ngModelChange)="updateFilingType()"
                                   [inline]="true" [options]="filingTypes" [formatter]="refData.enumFormatter" required></app-radio>
                      </app-form-field>
                    </div>
                  </div>
                  <ng-container *ngIf="showDetails()">
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Master bill of lading number" class="col-6 col-md-3"
                                        [value]="consignmentProcess.consignmentMasterLevel.consignmentNumber"
                                        [includeMargin]="false" [editMode]="editMode" [renderOptional]="false">
                          <ng-container *ngIf="isNewConsignment else blAsLabel">
                            <input class="input-group rounded border form-control"
                                   [(ngModel)]="consignmentProcess.consignmentMasterLevel.consignmentNumber" required
                                   (ngModelChange)="setConsignmentProcessId($event)"/>
                          </ng-container>
                          <ng-template #blAsLabel>
                            <span>{{ consignmentProcess.consignmentMasterLevel.consignmentNumber }}</span>
                          </ng-template>
                        </app-form-field>
                        <app-form-field label="Split from BL with MRN" class="col-6 col-md-3"
                                        [value]="consignmentProcess.filing.splitConsignmentPreviousMrn" [editMode]="editMode"
                                        [includeMargin]="false">
                          <input class="input-group rounded border form-control"
                                 [(ngModel)]="consignmentProcess.filing.splitConsignmentPreviousMrn"/>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Call reference number" class="col-6 col-md-3"
                                        [value]="consignmentProcess.filing.crn" [editMode]="editMode"
                                        [includeMargin]="false">
                          <app-search [(ngModel)]="consignmentProcess.filing.crn" (ngModelChange)="onCrnChange($event)"
                                      placeholder="Search for visit" dataKey="crn" [required]="isDutchPort"
                                      [inputFormatter]="visitFormatter" [resultFormatter]="visitFormatter"
                                      [minCharacters]="2" [searchFunction]="findVisits"></app-search>
                        </app-form-field>
                        <app-form-field label="Voyage number" class="col-6 col-md-3"
                                        [value]="consignmentProcess.consignmentMasterLevel.agentVoyageNumber"
                                        [editMode]="editMode" [includeMargin]="false">
                          <input class="input-group rounded border form-control" [disabled]="isDutchPort"
                                 [(ngModel)]="consignmentProcess.consignmentMasterLevel.agentVoyageNumber"/>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Vessel" class="col-6 col-md-3"
                                        [value]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.vessel" [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="vesselFormatter">
                          <app-search [(ngModel)]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.vessel"
                                      [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter" [disabled]="isDutchPort"
                                      [minCharacters]="3" placeholder="Name or IMO number" dataKey="imoCode" id="vesselName" required>
                          </app-search>
                        </app-form-field>
                        <app-form-field label="Carrier" class="col-6 col-md-3"
                                        [value]="consignmentProcess.consignmentMasterLevel.carrier" [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="utils.carrierFormatter">
                          <app-search required [(ngModel)]="consignmentProcess.consignmentMasterLevel.carrier" [disabled]="isDutchPort"
                                      [searchFunction]="utils.findCarriers" [resultFormatter]="utils.carrierFormatter" [inputFormatter]="utils.carrierFormatter">
                          </app-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <span class="d-block fw-regular mb-3">Route</span>
                      <p *ngIf="editMode">A minimum of 2 countries is required, one of which must be a member of the
                        European Union.</p>
                      <div class="consignment-routes row gy-4">
                        <div class="col-12" *ngFor="let route of routes; let i = index">
                          <app-master-consignment-route [routeInfo]="route" [index]="i" [editMode]="editMode"
                                                        [(allRoutes)]="routes"></app-master-consignment-route>
                        </div>
                      </div>
                      <div *ngIf="editMode" class="row mt-3">
                        <div class="col-auto">
                          <button class="btn btn-outline-primary text-decoration-none" (click)="addRoute()">
                            <i class="button-icon fa-light fa-plus"></i>
                            <span class="ps-2 d-none d-md-inline">Add country</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="editMode">
                      <div class="col-12">
                        <div class="row gy-4">
                          <app-form-field label="Place of acceptance" class="col-6 col-md-3"
                                          [value]="consignmentProcess.consignmentMasterLevel.placeOfAcceptance"
                                          [editMode]="editMode"
                                          [includeMargin]="false" [formatter]="utils.placeFormatter">
                            <app-search [(ngModel)]="consignmentProcess.consignmentMasterLevel.placeOfAcceptance"
                                        [inputFormatter]="refData.locationFormatter" [required]="utils.isMasterConsignment(consignmentProcess)"
                                        [searchFunction]="refData.findLocation"></app-search>
                          </app-form-field>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row gy-4">
                          <app-form-field label="Place of loading" class="col-6 col-md-3"
                                          [value]="consignmentProcess.consignmentMasterLevel.placeOfLoading"
                                          [editMode]="editMode"
                                          [includeMargin]="false" [formatter]="utils.placeFormatter">
                            <app-search [(ngModel)]="consignmentProcess.consignmentMasterLevel.placeOfLoading"
                                        [inputFormatter]="refData.locationFormatter"
                                        [searchFunction]="refData.findLocation" required></app-search>
                          </app-form-field>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row gy-4">
                          <app-form-field label="Estimated time of departure" class="col-6 col-md-6"
                                          [value]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfDeparture"
                                          [editMode]="editMode" [includeMargin]="false">
                            <app-date-field [(ngModel)]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfDeparture"></app-date-field>
                          </app-form-field>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="row gy-4">
                          <app-form-field label="Actual time of departure" class="col-6 col-md-6"
                                          [value]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.actualDateAndTimeOfDeparture"
                                          [editMode]="editMode" [includeMargin]="false">
                            <app-date-field [(ngModel)]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.actualDateAndTimeOfDeparture"></app-date-field>
                          </app-form-field>
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="European Union re-entry?" class="col-6 col-md-6"
                                        [value]="consignmentProcess.filing.reEntryIndicator"
                                        [editMode]="editMode" [includeMargin]="false">
                          <app-yes-no [required]="true" [(ngModel)]="consignmentProcess.filing.reEntryIndicator"></app-yes-no>
                        </app-form-field>
                      </div>
                    </div>
                    <div *ngIf="editMode" class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Port of discharge" class="col-6 col-md-4"
                                        [value]="consignmentProcess.consignmentMasterLevel.placeOfUnloading"
                                        [editMode]="editMode" [includeMargin]="false" [formatter]="utils.placeFormatter">
                          <app-search [(ngModel)]="consignmentProcess.consignmentMasterLevel.placeOfUnloading"
                                      (ngModelChange)="onPlaceOfUnloadingChange($event)"
                                      [inputFormatter]="refData.locationFormatter"
                                      [searchFunction]="refData.findLocation" required></app-search>
                        </app-form-field>
                        <app-form-field *ngIf="consignmentProcess.consignmentMasterLevel.placeOfUnloading" label="Terminal" class="col-6 col-md-4"
                                        [value]="consignmentProcess.consignmentMasterLevel.dischargeTerminal"
                                        [editMode]="editMode" [includeMargin]="false" [formatter]="refData.terminalFormatter">
                          <app-search [(ngModel)]="consignmentProcess.consignmentMasterLevel.dischargeTerminal" [inputFormatter]="refData.terminalFormatter"
                                      [searchFunction]="findTerminals" dataKey="terminalCode" placeholder="Search for terminal">
                          </app-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Customs office first entry" class="col-6 col-md-4"
                                        [value]="consignmentProcess.filing.customsOfficeOfFirstEntry"
                                        [editMode]="editMode" [includeMargin]="false" [formatter]="utils.customsOfficeFormatter">
                          <app-select [(ngModel)]="consignmentProcess.filing.customsOfficeOfFirstEntry" required
                                      [optionsProvider]="customsOffices" dataKey="name"
                                      [formatter]="utils.customsOfficeFormatter"></app-select>
                        </app-form-field>
                      </div>
                    </div>
                    <div *ngIf="editMode" class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Estimated time of arrival" class="col-6 col-md-6"
                                        [value]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfArrival"
                                        [editMode]="editMode" [includeMargin]="false">
                          <app-date-field [(ngModel)]="consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans.estimatedDateAndTimeOfArrival" required></app-date-field>
                        </app-form-field>
                      </div>
                    </div>
                    <div *ngIf="!utils.isStraightConsignment(consignmentProcess)" class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Place of delivery" class="col-6 col-md-3"
                                        [value]="consignmentProcess.consignmentMasterLevel.placeOfDelivery"
                                        [editMode]="editMode" [includeMargin]="false" [formatter]="utils.placeFormatter">
                          <app-search [(ngModel)]="consignmentProcess.consignmentMasterLevel.placeOfDelivery"
                                      [inputFormatter]="refData.locationFormatter" [required]="utils.isMasterConsignment(consignmentProcess)"
                                      [searchFunction]="refData.findLocation"></app-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <ng-container *ngIf="consignmentProcess.consignmentMasterLevel.transportCharges">
                          <app-form-field label="Method of payment" class="col col-md-6"
                                          [value]="consignmentProcess.consignmentMasterLevel.transportCharges.methodOfPayment"
                                          [editMode]="editMode" [includeMargin]="false"
                                          [formatter]="utils.methodOfPaymentFormatter">
                            <app-select [(ngModel)]="consignmentProcess.consignmentMasterLevel.transportCharges.methodOfPayment"
                                        dataKey="unCode" [options]="utils.methodsOfPayment"
                                        [required]="utils.isMasterConsignment(consignmentProcess)"
                                        [formatter]="utils.methodOfPaymentFormatter"></app-select>
                          </app-form-field>
                        </ng-container>
                        <ng-container *ngIf="consignmentProcess.consignmentMasterLevel.exchangeRate">
                          <app-form-field label="Currency" class="col-6 col-md-3"
                                          [value]="consignmentProcess.consignmentMasterLevel.exchangeRate.currency"
                                          [editMode]="editMode"
                                          [includeMargin]="false" [formatter]="refData.currenciesFormatter">
                            <app-select [(ngModel)]="consignmentProcess.consignmentMasterLevel.exchangeRate.currency"
                                        [nullOption]="true"
                                        dataKey="unCode" [optionsProvider]="refData.getCurrencies"
                                        [formatter]="refData.currenciesFormatter"
                                        [selectedFormatter]="refData.compactCurrenciesFormatter"></app-select>
                          </app-form-field>
                          <app-form-field label="Exchange rate" class="col col-md-3"
                                          [value]="consignmentProcess.consignmentMasterLevel.exchangeRate.rate"
                                          [editMode]="editMode"
                                          [includeMargin]="false">
                            <input class="input-group rounded border form-control"
                                   [(ngModel)]="consignmentProcess.consignmentMasterLevel.exchangeRate.rate"/>
                          </app-form-field>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Transport condition" class="col-6 col-md-4"
                                        [value]="consignmentProcess.consignmentMasterLevel.transportConditionCode"
                                        [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="refData.enumFormatter">
                          <app-select [(ngModel)]="consignmentProcess.consignmentMasterLevel.transportConditionCode"
                                      [options]="utils.transportConditionCodes"
                                      [formatter]="refData.enumFormatter"></app-select>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Movement reference number" class="col col-md-12"
                                        [includeMargin]="false" [value]="mrn" [editMode]="editMode">
                          <input class="input-group rounded border form-control" [(ngModel)]="mrn"/>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Authorisations" [editMode]="editMode"
                                        tooltip="Authorise organisations like surveyors to access the Discharge Information of this B/L (if bulk)"
                                        [value]="consignmentProcess.consignmentMasterLevel.bulkAuthorisations" [formatter]="bulkAuthorisationFormatter">
                          <app-multi-search [ngModel]="consignmentProcess.consignmentMasterLevel.bulkAuthorisations" [searchFunction]="refData.findBulkAuthorisations"
                                            (ngModelChange)="setBulkAuthorisations($event)" dataKey="shortName"
                                            [inputFormatter]="refData.bulkAuthorisationFormatter" [resultFormatter]="refData.bulkAuthorisationFormatter"
                                            placeholder="Find organisation">
                          </app-multi-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <app-form-field label="Customs status" class="col-6 col-md-3"
                                        [value]="consignmentProcess.consignmentMasterLevel.customsStatus" [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="refData.enumFormatter">
                          <app-select [(ngModel)]="consignmentProcess.consignmentMasterLevel.customsStatus"
                                      [options]="utils.customsStatuses"
                                      [formatter]="refData.enumFormatter"></app-select>
                        </app-form-field>
                        <app-form-field label="Customs process" class="col col-md-6"
                                        [value]="consignmentProcess.consignmentMasterLevel.customsProcess" [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="utils.customProcessNameFormatter">
                          <app-select [(ngModel)]="consignmentProcess.consignmentMasterLevel.customsProcess"
                                      [options]="utils.customsProcesses"
                                      [formatter]="utils.customProcessNameFormatter"></app-select>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <label class="h2 fw-regular">Documents</label>
                      <span *ngIf="editMode" class="form-field-optional text-secondary ms-1">(optional)</span>
                      <div class="row">
                        <app-form-field label="Supporting document" class="col col-md-7"
                                        [value]="consignmentProcess.consignmentMasterLevel.previousDocument.type" [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="utils.supportingDocumentTypeFormatter">
                          <app-select [(ngModel)]="consignmentProcess.consignmentMasterLevel.previousDocument.type" placeholder="Select document type"
                                      [options]="utils.documentTypes" [formatter]="utils.supportingDocumentTypeFormatter" required></app-select>
                        </app-form-field>
                        <app-form-field label="Reference number" [value]="consignmentProcess.consignmentMasterLevel.previousDocument.identification" class="col col-md-3"
                                        [editMode]="editMode" [includeMargin]="false">
                          <input class="input-group rounded border form-control" required
                                 [(ngModel)]="consignmentProcess.consignmentMasterLevel.previousDocument.identification"/>
                        </app-form-field>
                      </div>
                    </div>
                    <ng-container *ngFor="let supportingDocument of consignmentProcess.consignmentMasterLevel.supportingDocuments; index as i; trackBy: refData.trackByIndex">
                      <div class="col-12">
                        <div class="row">
                          <app-form-field label="Previous document" class="col col-md-7" [truncateReadOnly]="true"
                                          [value]="supportingDocument.type" [editMode]="editMode"
                                          [includeMargin]="false" [formatter]="utils.documentTypeFormatter">
                            <app-search [(ngModel)]="supportingDocument.type" [inputFormatter]="utils.documentTypeFormatter"
                                        [searchFunction]="utils.findDocumentTypes" placeholder="Select a document type"></app-search>
                          </app-form-field>
                          <app-form-field label="Reference number" [value]="supportingDocument.referenceNumber" class="col col-md-3"
                                          [editMode]="editMode" [includeMargin]="false">
                            <input class="input-group rounded border form-control" required
                                   [(ngModel)]="supportingDocument.referenceNumber"/>
                          </app-form-field>
                          <div *ngIf="editMode" class="col-auto d-flex align-items-center pt-5">
                            <button class="btn rounded-circle p-1 px-2 text-danger h1 mb-0" (click)="deleteSupportingDocument(supportingDocument)">
                              <i class="fa-light fa-trash-can"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-12">
                      <div *ngIf="editMode" class="row mt-3">
                        <div class="col-auto">
                          <button class="btn btn-outline-primary text-decoration-none" (click)="addSupportingDocument()">
                            <i class="button-icon fa-light fa-plus"></i>
                            <span class="ps-2 d-none d-md-inline">Add document</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <ng-container *ngIf="showDetails()">
        <app-tab-item [templateRef]="supplyChainActorsRef">
        <span class="tab-item">Parties</span>
        <ng-template #supplyChainActorsRef>
          <div class="container py-3">
            <div class="card">
              <div class="card-body p-5">
                <div class="row gy-4 my-3">
                  <h5 class="h1 col-12 m-0">Parties</h5>
                  <div class="col-12">
                    <app-consignment-party-details
                      [(party)]="consignmentProcess.consignmentMasterLevel.consignee"
                      [editMode]="editMode"
                      label="Consignee"></app-consignment-party-details>
                  </div>
                  <div class="col-12">
                    <app-consignment-party-details
                      [(party)]="consignmentProcess.consignmentMasterLevel.consignor"
                      [editMode]="editMode" [required]="utils.isMasterConsignment(consignmentProcess)"
                      label="Consignor"></app-consignment-party-details>
                  </div>
                  <div class="col-12">
                    <app-consignment-supplementary-declarant
                      [(supplementaryDeclarant)]="consignmentProcess.consignmentMasterLevel.supplementaryDeclarant"
                      [editMode]="editMode" [required]="utils.isMasterConsignment(consignmentProcess)"
                      label="Supplementary declarant"></app-consignment-supplementary-declarant>
                  </div>
                </div>
                <div class="row gy-4 my-3">
                  <h5 class="h1 col-12 m-0">Supply chain actors</h5>
                  <div class="col-12" *ngFor="let supplyChainActor of consignmentProcess.consignmentMasterLevel.additionalSupplyChainActors; index as i; trackBy: refData.trackByIndex">
                    <app-additional-supply-chain-actor-details
                      [supplyChainActor]="supplyChainActor" [editMode]="editMode" (supplyChainActorChange)="checkSupplyChainActorDeletion($event, i)"
                      [label]="utils.supplyChainTypeFormatter(supplyChainActor.type)"></app-additional-supply-chain-actor-details>
                  </div>
                  <div class="col-12" *ngIf="editMode">
                    <div class="col-auto">
                      <button type="button" class="btn btn-primary" (click)="addSupplyChainActor()">Add actor</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        </app-tab-item>
        <app-tab-item *ngIf="utils.houseConsignmentSupported(consignmentProcess)" [templateRef]="houseConsignmentsRef">
          <span class="tab-item">House B/Ls
            <span class="ps-2 sub-text fw-light">{{consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel.length}}</span>
          </span>
          <ng-template #houseConsignmentsRef>
            <app-house-consignments-table [data]="houseConsignments" [editable]="isEditable"
                                          [consignmentProcess]="consignmentProcess"></app-house-consignments-table>
          </ng-template>
        </app-tab-item>
        <app-tab-item *ngIf="equipmentSupported" [templateRef]="equipmentRef">
          <span class="tab-item">Equipment
            <span class="ps-2 sub-text fw-light">{{transportEquipmentCount}}</span>
          </span>
          <ng-template #equipmentRef>
            <app-consignment-equipments-table [data]="equipments" [editable]="isEditable"
                                              [consignmentProcess]="consignmentProcess"></app-consignment-equipments-table>
          </ng-template>
        </app-tab-item>
        <app-tab-item [templateRef]="goodsRef">
          <span class="tab-item">Goods
            <span class="ps-2 sub-text fw-light">{{goodsItems.length}}</span>
          </span>
          <ng-template #goodsRef>
            <app-consignment-goods-table [data]="goodsItems" [editable]="isEditable"
                                         [consignmentProcess]="consignmentProcess"></app-consignment-goods-table>
          </ng-template>
        </app-tab-item>
      </ng-container>
    </app-tab-panel>
  </div>

  <div class="modal-footer" *ngIf="!consignmentProcess.cancelled">
    <div class="d-flex flex-grow-1">
      <div class="container">
        <div class="row mx-4">
          <div class="col-auto">
            <button type="button" class="btn btn-outline-danger" (click)="remove()">Remove</button>
          </div>
          <div class="col-auto ms-auto">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close"
                    *ngIf="!editMode">
              Close
            </button>
            <button type="button" class="btn btn-outline-secondary" *ngIf="editMode" (click)="toggleEdit()">
              Cancel
            </button>
          </div>
          <div class="col-auto">
            <ng-container *ngIf="editMode else readMode">
              <button type="button" class="btn btn-primary" (click)="toggleEdit()"
                      *ngIf="utils.hasBeenDeclared(consignmentProcess)">Ok</button>
              <button type="button" class="btn btn-primary" (click)="save()"
                      *ngIf="!utils.hasBeenDeclared(consignmentProcess)">Save
              </button>
              <button type="button" class="btn btn-primary mx-4" (click)="declare()"
                      *ngIf="!utils.hasBeenDeclared(consignmentProcess) && isAllowedToDeclare">Declare
              </button>
            </ng-container>
            <ng-template #readMode>
              <button *ngIf="isEditable" type="button" class="btn btn-outline-secondary me-4" (click)="toggleEdit()">Edit</button>
              <button *ngIf="isAllowedToDeclare" type="button" class="btn btn-primary" (click)="declare()">
                {{ utils.hasBeenDeclared(consignmentProcess) ? 'Update' : 'Declare' }}
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #filingTypeTooltip>
  <div class="text-start">
    <h6>Filing type</h6>
    <p>‘Single filing’ means you submit all necessary Entry Summary Declaration (ENS) data.</p>
    <p>‘Single filing’ in combination with ‘Master Bill of Lading’ means you also have to provide (multiple) House Bill of Lading details.</p>
    <p>‘Multiple filing’ means that parts of the ENS data is provided by the ‘Supplementary declarant’ you specify.</p>
  </div>
</ng-template>

<ng-template #billOfLadingTooltip>
  <div class="text-start">
    <h6>Bill of lading</h6>
    <p>‘Straight’ Bill of Lading (B/L) is non-negotiable and is also known as 'B/L op naam' or 'Recta connossement'.</p>
    <p>To help you choose, you can check the following on your B/L: Is the consignee known by name or unknown (‘To order’)? If known, we suggest choosing ‘Straight’. If unknown, we suggest choosing ‘Master’.</p>
    <p>If you are not familiar with using B/L’s but ‘Bookings’, we also suggest using ‘Straight’.</p>
  </div>
</ng-template>
