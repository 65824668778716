import {
  IE3FilingType,
} from "@portbase/bezoekschip-service-typescriptmodels";

export class ContainerUtils {

  static statusFormatter = (value: String) => {
    switch (value) {
      case "EXPECTED":
        return "Expected";
       case "Dischared":
        return "Discharged";
      case null:
        return "";
    }
  }

}
