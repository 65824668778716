import {Component, Input} from '@angular/core';
import {IE3GoodsItemSummary} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-consignment-goods-column',
  templateUrl: './consignment-goods-column.component.html',
  styleUrls: ['./consignment-goods-column.component.scss']
})
export class ConsignmentGoodsColumnComponent {
  @Input() term: string;
  @Input() goods: IE3GoodsItemSummary[];

  trackByGoodsItem = (index: number, record: IE3GoodsItemSummary) => record.goodsItemNumber;

  goodsAsText = (goodsItem: IE3GoodsItemSummary) =>
    `${goodsItem.commodity.commodityCode.harmonizedSystemSubHeadingCode} – ${goodsItem.commodity.descriptionOfGoods}`
}
