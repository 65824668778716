<div class="my-3"><i>Berths are synced to WPCS refdata</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="berth['id']" disabled required></app-form-group>
    <app-form-group><label>Port code</label>
      <app-select id="port" [(ngModel)]="berth['portCode']" [optionsProvider]="portsOfCall" required
                  [addSelectedIfNotExists]="true" placeholder="– Select a port –">
      </app-select>
    </app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="berth.name" required></app-form-group>
    <app-form-group><label>Short name</label><input class="form-control" [(ngModel)]="berth.shortName">
    </app-form-group>
    <app-form-group><label>Code</label><input class="form-control" [(ngModel)]="berth.code" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Terminal</label>
      <app-search [ngModel]="berth" [searchFunction]="searchTerminal" (ngModelChange)="setTerminal($event)"
                  id="terminal" placeholder="Find terminal" [inputFormatter]="terminalFormatter">
      </app-search>
    </app-form-group>
    <app-form-group><label>Berth group code</label><input class="form-control" [(ngModel)]="berth.berthGroupCode"></app-form-group>
    <app-form-group><label>Buoy</label><app-yes-no [(ngModel)]="berth.buoy" required id="buoy"></app-yes-no></app-form-group>
    <app-form-group><label>Bollard from</label><input type="number" class="form-control" [ngModel]="berth.bollards?.start" (ngModelChange)="setBollards($event, true)" [required]="!!this.berth.bollards"></app-form-group>
    <app-form-group><label>Bollard to</label><input type="number" class="form-control" [ngModel]="berth.bollards?.end" (ngModelChange)="setBollards($event, false)" [required]="!!this.berth.bollards"></app-form-group>
    <app-form-group><label>Party to notify</label><input class="form-control" [(ngModel)]="berth.partyToNotify"></app-form-group>
  </div>
</div>
