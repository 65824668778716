import {AfterViewInit, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {TableField} from "../../../../../common/table/table-view/table-view.component";
import {IE3ConsignmentProcess, IE3GoodsPlacementSummary} from "@portbase/bezoekschip-service-typescriptmodels";
import {openEditModal} from "../../../../../common/utils";
import {
  GoodsConsignmentDetailsComponent,
  GoodsConsignmentDetailsComponentData
} from "../../goods-consignment-details/goods-consignment-details.component";
import {GoodsItemWithHouseConsignments} from "../../../consignment.utils";
import {
  EquipmentConsignmentDetailsComponent,
  EquipmentConsignmentDetailsComponentData
} from "../../equipment-consignment-details/equipment-consignment-details.component";

@Component({
  selector: 'app-consignment-goods-table',
  templateUrl: './consignment-goods-table.component.html',
  styleUrls: ['./consignment-goods-table.component.scss']
})
export class ConsignmentGoodsTableComponent implements AfterViewInit {
  @Input() consignmentProcess: IE3ConsignmentProcess;
  @Input() houseConsignmentNumber: string;
  @Input() equipmentNumber: string;
  @Input() term: string;
  @Input() data: GoodsItemWithHouseConsignments[];
  @Input() editable: boolean = true;
  fieldsDefinition: TableField[] = [];

  @ViewChild("placementsRef") placementsRef: TemplateRef<any>;
  @ViewChild("goodsRef") goodsRef: TemplateRef<any>;
  @ViewChild("actionColumnRef") actionColumnRef: TemplateRef<any>;
  @ViewChild("houseConsignmentRef") houseConsignmentRef: TemplateRef<any>;

  ngAfterViewInit(): void {
    this.fieldsDefinition = [
      {
        type: "string",
        header: "Goods",
        name: "goodsItem",
        template: this.goodsRef
      }, {
        type: "string",
        name: "goodsItem.placements",
        header: "Equipment",
        template: this.placementsRef,
        hidden: !!this.equipmentNumber
      }, {
        type: "string",
        name: "houseConsignments",
        header: "House B/Ls",
        hidden: !!this.houseConsignmentNumber,
        template: this.houseConsignmentRef
      }, {
        type: "action",
        name: "actionColumn",
        template: this.actionColumnRef,
        cellClass: "p-1 auto-width"
      }
    ]
  }

  trackByContainerIdentificationNumber = (index: number, placement: IE3GoodsPlacementSummary) => placement.containerIdentificationNumber;

  trackByGoodsItemNumber = (index: number, goodsItem: GoodsItemWithHouseConsignments) => goodsItem.goodsItem.goodsItemNumber;

  edit = (record: GoodsItemWithHouseConsignments) =>
    openEditModal(GoodsConsignmentDetailsComponent, <GoodsConsignmentDetailsComponentData>{
      consignmentProcessId: this.consignmentProcess.consignmentProcessId,
      goodsItemNumber: record?.goodsItem.goodsItemNumber,
      houseConsignmentNumber: this.houseConsignmentNumber || (record?.houseConsignments?.length ? record.houseConsignments[0] : null),
      equipmentNumber: this.equipmentNumber,
      cachedConsignmentProcess: this.consignmentProcess
    }, {
      warnOnClose: true,
      currentToStack: true
    });

  editEquipment = (equipmentNumber: string) => openEditModal(EquipmentConsignmentDetailsComponent, <EquipmentConsignmentDetailsComponentData>{
    consignmentProcessId: this.consignmentProcess.consignmentProcessId,
    equipmentNumber: equipmentNumber,
    cachedConsignmentProcess: this.consignmentProcess,
    houseConsignmentNumber: this.houseConsignmentNumber
  }, {
    warnOnClose: true,
    currentToStack: true
  })
}
