// noinspection ES6UnusedImports
import * as bootstrap from 'bootstrap';

import {RouterModule, Routes} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injector, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {VisitComponent} from './visit-details/visit/visit.component';
import {VisitOverviewComponent} from './visit-overview/visit-overview.component';
import {NewVisitComponent} from './new-visit/new-visit.component';
import {NewsComponent} from './news/news.component';
import {PreferencesComponent} from './preferences/preferences.component';
import {VisitDetailsComponent} from './visit-details/visit-details.component';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SecurityComponent} from './visit-details/security/security.component';
import {WasteComponent} from './visit-details/waste/waste.component';
import {PassengersComponent} from './visit-details/passengers/passengers.component';
import {PassengerSummaryComponent} from './visit-details/passengers/summary/passenger-summary.component';
import {ShipStoresComponent} from './visit-details/ship-stores/ship-stores.component';
import {AuthoriseComponent} from './visit-details/authorise/authorise.component';
import {FinancialDeclarantComponent} from './visit-details/financial-declarant/financial-declarant.component';
import {InjectorProvider} from './common/injector-provider';
import {RefdataQueryHandlerMock} from './mocking/refdata-query-handler.mock';
import {BezoekschipQueryHandlerMock} from './mocking/bezoekschip-query-handler.mock';
import {UserHandlerMock} from './mocking/user-handler.mock';
import {QueryGateway} from './common/query-gateway';
import {CommandGateway} from './common/command-gateway';
import {EventGateway} from './common/event-gateway';
import {HttpClientModule} from '@angular/common/http';
import {NextNextPortsComponent, NextPortsComponent} from './visit-details/visit/next-ports/next-ports.component';
import {DragulaModule} from 'ng2-dragula';
import {PreviousPortsComponent} from './visit-details/visit/previous-ports/previous-ports.component';
import {EditMovementComponent} from './visit-details/visit/edit-movement/edit-movement.component';
import {
  EditNauticalServiceComponent
} from './visit-details/visit/locations/edit-berth/edit-nautical-service/edit-nautical-service.component';
import {TimestampPipe} from './common/date/timestamp.pipe';
import {EtaPortAisComponent} from './visit-details/visit/eta-port-ais/eta-port-ais.component';
import {EditBerthComponent} from './refdata/edit-berth/edit-berth.component';
import {environment} from '../environments/environment';
import {BezoekschipCommandHandlerMock} from './mocking/bezoekschip-command-handler.mock';

import {HardcodedRefdataQueryHandler} from './refdata/hardcoded-refdata-query-handler';
import {
  ConfirmationOfPurchaseComponent
} from './visit-details/confirmation-of-purchase/confirmation-of-purchase.component';
import {
  NotificationOfDepartureComponent
} from './visit-details/notification-of-departure/notification-of-departure.component';
import {DeclarationStatusComponent} from './visit-details/visit-status/declaration-status/declaration-status.component';
import {ClearanceComponent} from './visit-details/visit/clearance/clearance.component';
import {ClearanceStatusComponent} from './visit-details/visit-status/clearance-status/clearance-status.component';
import {CookieModule} from 'ngx-cookie';
import {VisitStatusComponent} from './visit-details/visit-status/visit-status.component';
import {SentryErrorHandler} from './sentry-error-handler';
import {VisitAdminComponent} from './visit-details/visit/visit-admin/visit-admin.component';
import {PassengersAdminComponent} from './visit-details/passengers/passengers-admin/passengers-admin.component';
import {ShipStoresAdminComponent} from './visit-details/ship-stores/ship-stores-admin/ship-stores-admin.component';
import {SecurityAdminComponent} from './visit-details/security/security-admin/security-admin.component';
import {WasteAdminComponent} from './visit-details/waste/waste-admin/waste-admin.component';
import {ReportIssueComponent} from './report-issue/report-issue.component';
import {RegisterAtaComponent} from './visit-details/visit/locations/edit-berth/register-ata/register-ata.component';
import {RegisterAtdComponent} from './visit-details/visit/locations/edit-berth/register-atd/register-atd.component';
import {MessageOverviewComponent} from './message-overview/message-overview.component';
import {VesselDetailsComponent} from './visit-details/vessel-details/vessel-details.component';
import {RefdataComponent} from './refdata/refdata.component';
import {RefdataUpdateComponent} from './refdata/refdata-update/refdata-update.component';
import {EditPortComponent} from './refdata/edit-port/edit-port.component';
import {EditPortOfCallComponent} from "./refdata/edit-port-of-call/edit-port-of-call.component";
import {EditPortFacilityComponent} from './refdata/edit-port-facility/edit-port-facility.component';
import {EditCarrierComponent} from './refdata/edit-carrier/edit-carrier.component';
import {EditVesselComponent} from './refdata/edit-vessel/edit-vessel.component';
import {EditPilotExemptionComponent} from './refdata/edit-pilot-exemption/edit-pilot-exemption.component';
import {EditDangerousGoodComponent} from './refdata/edit-dangerous-good/edit-dangerous-good.component';
import {
  EditContainerGoodComponent
} from './refdata/edit-dangerous-good/edit-container-good/edit-container-good.component';
import {EditSolidGoodComponent} from './refdata/edit-dangerous-good/edit-solid-good/edit-solid-good.component';
import {EditOilComponent} from './refdata/edit-dangerous-good/edit-oil/edit-oil.component';
import {EditGasComponent} from './refdata/edit-dangerous-good/edit-gas/edit-gas.component';
import {EditChemicalComponent} from './refdata/edit-dangerous-good/edit-chemical/edit-chemical.component';
import {EditLocationComponent} from './refdata/edit-location/edit-location.component';
import {EditVesselServiceComponent} from './refdata/edit-vessel-service/edit-vessel-service.component';
import {EncryptComponent} from "./admin/encrypt/encrypt.component";
import {DangerousGoodsComponent} from './visit-details/dangerous-goods/dangerous-goods.component';
import {StowagesComponent} from './visit-details/dangerous-goods/stowages/stowages.component';
import {HandlingsComponent} from './visit-details/dangerous-goods/handlings/handlings.component';
import {
  EditDischargeComponent
} from './visit-details/dangerous-goods/handlings/edit-discharge/edit-discharge.component';
import {EditLoadingComponent} from './visit-details/dangerous-goods/handlings/edit-loading/edit-loading.component';
import {EditWashingComponent} from './visit-details/dangerous-goods/handlings/edit-washing/edit-washing.component';
import {EditInertingComponent} from './visit-details/dangerous-goods/handlings/edit-inerting/edit-inerting.component';
import {EditStowageComponent} from './visit-details/dangerous-goods/stowages/edit-stowage/edit-stowage.component';
import {
  DangerousGoodsOverviewComponent
} from './visit-details/dangerous-goods/dangerous-goods-overview/dangerous-goods-overview.component';
import {
  DangerousGoodsAdminComponent
} from './visit-details/dangerous-goods/dangerous-goods-admin/dangerous-goods-admin.component';
import {EditBlendingComponent} from './visit-details/dangerous-goods/handlings/edit-blending/edit-blending.component';
import {
  EditVentilationComponent
} from './visit-details/dangerous-goods/handlings/edit-ventilation/edit-ventilation.component';
import {VisitHistoryComponent} from './visit-details/visit-history/visit-history.component';
import {RegisterAtdPortComponent} from './visit-details/visit/register-atd-port/register-atd-port.component';
import {EditRestowComponent} from './visit-details/dangerous-goods/handlings/edit-restow/edit-restow.component';
import {
  StormPilotageComponent
} from './visit-details/visit/locations/edit-berth/storm-pilotage/storm-pilotage.component';
import {PortEntryComponent} from './visit-details/visit/port-entry-tab/port-entry/port-entry.component';
import {SendEmailTokenComponent} from './send-email-token/send-email-token.component';
import {UserMenuComponent} from './navigation/user-menu/user-menu.component';
import {CargoImportComponent} from './visit-details/cargo-import/cargo-import.component';
import {
  ImportManifestAdminComponent
} from './visit-details/cargo-import/import-declaration/consignments/manifest-admin/import-manifest-admin.component';
import {PartyComponent} from './visit-details/cargo-import/common/party/party.component';
import {
  WarehouseLicenseComponent
} from './visit-details/cargo-import/common/warehouse-license/warehouse-license.component';
import {TransitGuaranteeComponent} from './transit/transit-guarantees/transit-guarantee/transit-guarantee.component';
import {
  ConsignmentDetailsComponent
} from './visit-details/cargo-import/import-declaration/consignment-details/consignment-details.component';
import {
  GoodsPlacementComponent
} from './visit-details/cargo-import/import-declaration/goods-placement/goods-placement.component';
import {GoodsComponent} from './visit-details/cargo-import/import-declaration/goods/goods.component';
import {
  GoodDetailsComponent
} from './visit-details/cargo-import/import-declaration/good-details/good-details.component';
import {
  GoodsDocumentsComponent
} from './visit-details/cargo-import/import-declaration/goods-documents/goods-documents.component';
import {
  ConsignmentsComponent
} from './visit-details/cargo-import/import-declaration/consignments/consignments.component';
import {
  ImportContainerComponent
} from './visit-details/cargo-import/common/import-container/import-container.component';
import {ContainersComponent} from './visit-details/cargo-import/import-declaration/containers/containers.component';
import {
  OnCarriageDetailsComponent
} from './visit-details/cargo-import/import-declaration/on-carriage-details/on-carriage-details.component';
import {ImportDeclarationComponent} from './visit-details/cargo-import/import-declaration/import-declaration.component';
import {DischargeListComponent} from './visit-details/cargo-import/discharge-list/discharge-list.component';
import {
  DischargeListAdminComponent
} from './visit-details/cargo-import/discharge-list/discharge-list-admin/discharge-list-admin.component';
import {CargoSummaryComponent} from './visit-details/cargo-import/common/cargo-summary/cargo-summary.component';
import {AddressBookComponent} from './visit-details/cargo-import/address-book/address-book.component';
import {LicenseBookComponent} from './visit-details/cargo-import/license-book/license-book.component';
import {
  PortEntryWarningsComponent
} from './visit-details/visit/port-entry-tab/port-entry/port-entry-warnings/port-entry-warnings.component';
import {OverlandersComponent} from './visit-details/cargo-import/import-declaration/overlanders/overlanders.component';
import {EditTerminalComponent} from './refdata/edit-terminal/edit-terminal.component';
import {DepartureVoyageComponent} from './visit-details/visit/departure-voyage/departure-voyage.component';
import {ArrivalVoyageComponent} from './visit-details/visit/arrival-voyage/arrival-voyage.component';
import {
  ExitPointComponent,
  NextExitPointComponent
} from './visit-details/visit/locations/exit-point/exit-point.component';

import {ModalConfirmAutofocus} from './common/modal/modal-confirm.component';
import {ModalErrorAutofocus} from "./common/modal-error/modal-error.component";
import {EntryPointComponent} from './visit-details/visit/port-entry-tab/entry-point/entry-point.component';
import {
  ConsignmentsAdminComponent
} from './visit-details/cargo-import/import-declaration/consignments/consignments-admin/consignments-admin.component';
import {
  CargoFilterComponent
} from './visit-details/cargo-import/import-declaration/import-filter/cargo-filter.component';
import {TidalWindowComponent} from './visit-details/visit/tidal-window/tidal-window.component';
import {FormattedDateRangeComponent} from './common/date/formatted-date-range/formatted-date-range.component';
import {HistoryRibbonComponent} from './visit-details/ribbon/history-ribbon.component';
import {CollectorSummaryComponent} from './waste-collector/collector-summary/collector-summary.component';
import {CollectorOverviewComponent} from './waste-collector/collector-overview/collector-overview.component';
import {
  WasteCollectionPdfComponent
} from './waste-collector/waste-collection/waste-collection-pdf/waste-collection-pdf.component';
import {WasteCollectionComponent} from './waste-collector/waste-collection/waste-collection.component';
import {TimelineComponent} from './visit-details/timeline/timeline.component';
import {DefectsComponent} from './visit-details/visit/defects/defects.component';
import {InspectionsComponent} from './visit-details/visit/inspections/inspections.component';
import {
  PilotBoardingPlaceComponent
} from './visit-details/visit/port-entry-tab/port-entry/pilot-boarding-place/pilot-boarding-place.component';
import {OrderStatusComponent} from './visit-details/visit/order-status/order-status.component';
import {SelectBerthComponent} from './visit-details/visit/select-berth/select-berth.component';
import {AgentTransferComponent} from './visit-details/visit/agent-transfer/agent-transfer.component';
import {AddRemoveBerthComponent} from './visit-details/visit/add-remove-berth/add-remove-berth.component';
import {
  EntryDependencyIconComponent
} from './visit-details/visit/entry-dependency-icon/entry-dependency-icon.component';
import {
  EarliestTimePortEntryComponent
} from './visit-details/visit/port-entry-tab/port-entry/earliest-time-port-entry/earliest-time-port-entry.component';
import {PortEntryTabComponent} from './visit-details/visit/port-entry-tab/port-entry-tab.component';
import {PilotStationComponent} from './visit-details/visit/port-entry-tab/pilot-station/pilot-station.component';
import {MovementComponent} from './visit-details/visit/movement/movement.component';
import {BerthVisitComponent} from './visit-details/visit/berth-visit/berth-visit.component';
import {PortArrivalComponent} from './visit-details/visit/port-arrival/port-arrival.component';
import {PortDepartureComponent} from './visit-details/visit/port-departure/port-departure.component';
import {
  BerthVisitDetailsComponent
} from './visit-details/visit/berth-visit/berth-visit-details/berth-visit-details.component';
import {
  BerthVisitTimesComponent
} from './visit-details/visit/berth-visit/berth-visit-details/berth-visit-times.component';
import {
  BoatmenToBerthSummaryComponent
} from './visit-details/visit/boatmen-to-berth-summary/boatmen-to-berth-summary.component';
import {TugboatsSummaryComponent} from './visit-details/visit/tugboats-summary/tugboats-summary.component';
import {NauticalServicesComponent} from './visit-details/visit/nautical-services/nautical-services.component';
import {MovementSummaryComponent} from './visit-details/visit/movement-summary/movement-summary.component';
import {
  MovementDurationSummaryComponent
} from './visit-details/visit/movement-duration-summary/movement-duration-summary.component';
import {
  CargoImportAdminComponent
} from './visit-details/cargo-import/import-declaration/admin/cargo-import-admin.component';
import {EditSizeTypeComponent} from './refdata/edit-sizetype/edit-sizetype.component';
import {EditTerminalEmailComponent} from './refdata/edit-terminal-email/edit-terminal-email.component';
import {EditDangerInformationComponent} from './refdata/edit-dangerous-good-cargo/edit-danger-information.component';
import {HistoryComponent} from './history/history.component';
import {PaginationComponent} from './common/pagination/pagination.component';
import {ActionNavBarComponent} from './visit-details/action-nav-bar/action-nav-bar.component';
import {ExchangeAtBerthComponent} from './visit-details/visit/exchange-at-berth/exchange-at-berth.component';
import {HealthDeclarationComponent} from './visit-details/health-declaration/health-declaration.component';
import {CargoLoadingComponent} from './visit-details/cargo-loading/cargo-loading.component';
import {LoadListComponent} from './visit-details/cargo-loading/load-list/load-list.component';
import {
  LoadingOverlandersComponent
} from './visit-details/cargo-loading/loading-overlanders/loading-overlanders.component';
import {LoadContainerComponent} from './visit-details/cargo-loading/load-container/load-container.component';
import {
  NauticalServicesInboundComponent
} from './visit-details/visit/nautical-services-inbound/nautical-services-inbound.component';
import {EtaEuEntryComponent} from './visit-details/visit/eta-eu-entry/eta-eu-entry.component';
import {
  HealthDeclarationAdminComponent
} from './visit-details/health-declaration/health-declaration-admin/health-declaration-admin.component';
import {
  MovementBetweenBerthsComponent
} from './visit-details/visit/movement-between-berths/movement-between-berths.component';
import {TimelineDateTimeComponent} from './visit-details/visit/timeline-date-time/timeline-date-time.component';
import {TimelineIconComponent} from './visit-details/visit/timeline-icon/timeline-icon.component';
import {
  DeclarationStatusHealthComponent
} from './visit-details/visit-status/declaration-status-health/declaration-status-health.component';
import {
  CargoImportOverviewComponent
} from './visit-details/cargo-import/cargo-import-overview/cargo-import-overview.component';
import {GeneralInformationComponent} from './visit-details/visit/general-information/general-information.component';
import {WasteAtBerthIconComponent} from './visit-details/visit/waste-at-berth-icon/waste-at-berth-icon.component';

import {TransitGuaranteesComponent} from './transit/transit-guarantees/transit-guarantees.component';
import {TransitOverviewComponent} from './transit/transit-overview.component';
import {ItineraryComponent} from './waste-collector/collector-summary/itinerary/itinerary.component';
import {
  CollectorSummaryTitleBarComponent
} from './waste-collector/collector-summary/collector-summary-title-bar/collector-summary-title-bar.component';
import {TransitSummaryComponent} from "./transit/transit-summary/transit-summary.component";
import {TransitDeclarationComponent} from "./transit/transit-declaration/transit-declaration.component";
import {
  TransitGoodComponent
} from "./transit/transit-declaration/transit-goods/transit-goods-item/transit-good.component";
import {TransitDetailsComponent} from "./transit/transit-declaration/transit-details/transit-details.component";
import {TransitGoodsComponent} from "./transit/transit-declaration/transit-goods/transit-goods.component";
import {
  ShipToShipActivitiesComponent
} from "./visit-details/security/ship-to-ship-activities/ship-to-ship-activities.component";
import {TransitHistoryComponent} from "./transit/transit-declaration/transit-history/transit-history.component";
import {TransitFilterComponent} from "./transit/transit-filter/transit-filter.component";
import {GuaranteeCommandHandlerMock} from "./mocking/guarantee-command-handler.mock";
import {
  GuaranteeAdminComponent
} from "./transit/transit-guarantees/transit-guarantee/guarantee-admin/guarantee-admin.component";
import {FluxSearchImportComponent} from './refdata/flux-search-import/flux-search-import.component';
import {
  TransitGoodsDocumentsComponent
} from "./transit/transit-declaration/transit-goods/transit-goods-documents/transit-goods-documents.component";
import {TransitTemplatesComponent} from "./transit/transit-templates/transit-templates.component";
import {TransitTemplateComponent} from "./transit/transit-templates/transit-template/transit-template.component";
import {
  TransitDeclarationAdminComponent
} from "./transit/transit-declaration/transit-declaration-admin/transit-declaration-admin.component";
import {
  TransitProofOfArrivalComponent
} from "./transit/transit-declaration/transit-proof-of-arrival/transit-proof-of-arrival.component";
import {
  TransitCopyContainersFromConsignmentsComponent
} from "./transit/transit-copy-from-consignments/transit-copy-containers-from-consignments/transit-copy-containers-from-consignments.component";
import {TransitPartyComponent} from "./transit/transit-address-book/transit-party/transit-party.component";
import {TransitAddressBookComponent} from "./transit/transit-address-book/transit-address-book.component";
import {FluxSearchExportComponent} from "./refdata/flux-search-export/flux-search-export.component";
import {
  PreviousPortsAndFacilitiesComponent
} from "./visit-details/security/previous-ports-and-facilities/previous-ports-and-facilities.component";
import {VisitFilterComponent} from "./visit-overview/visit-filter/visit-filter.component";
import {TravelTimesQueryHandler} from "./refdata/travel-times-query-handler";
import {CommercialReleaseOverviewComponent} from "./commercial-release/commercial-release-overview.component";
import {
  CommercialReleaseDashboardComponent
} from "./commercial-release/commercial-release-dashboard/commercial-release-dashboard.component";
import {
  CommercialReleaseDeclarationComponent
} from "./commercial-release/commercial-release-declaration/commercial-release-declaration.component";
import {
  CommercialReleaseDetailsComponent
} from "./commercial-release/commercial-release-declaration/commercial-release-details/commercial-release-details.component";
import {
  CommercialReleaseFilterComponent
} from "./commercial-release/commercial-release-filter/commercial-release-filter.component";
import {
  CommercialReleaseAdminComponent
} from "./commercial-release/commercial-release-declaration/commercial-release-admin/commercial-release-admin.component";
import {
  CommercialReleaseSummaryComponent
} from "./commercial-release/commercial-release-summary/commercial-release-summary.component";
import {MidOverviewComponent} from './transit/mid-overview/mid-overview.component';
import {
  TransitDeclarationSendTriggerInfoComponent
} from "./transit/transit-declaration-send-trigger-info/transit-declaration-send-trigger-info.component";
import {
  BatchTransitDeclarationComponent
} from './transit/batch-transit-declaration/batch-transit-declaration.component';
import {
  TransitDeclarationsFromContainersComponent
} from './transit/transit-copy-from-consignments/transit-declarations-from-containers/transit-declarations-from-containers.component';
import {
  TransitCopyBulkFromConsignmentsComponent
} from "./transit/transit-copy-from-consignments/transit-copy-bulk-from-consignments/transit-copy-bulk-from-consignments.component";
import {ReleaseToPartyOverviewComponent} from './release-to-party-overview/release-to-party-overview.component';
import {ReleaseToPartyComponent} from './release-to-party-overview/release-to-party/release-to-party.component';
import {EditWasteMaterialComponent} from "./refdata/edit-waste-material/edit-waste-material.component";
import {
  TransitGuaranteeHistoryComponent
} from "./transit/transit-guarantees/transit-guarantee-history/transit-guarantee-history.component";
import {
  CommercialReleaseHistoryComponent
} from './commercial-release/commercial-release-declaration/commercial-release-history/commercial-release-history.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {AdminPanelComponent} from './admin/admin-panel/admin-panel.component';
import {FormComponent} from './common/form/form.component';
import {SspiClearanceComponent} from "./visit-details/visit/clearance/sspi.clearance.component";
import {EkhClearanceComponent} from "./visit-details/visit/clearance/ekh.clearance.component";
import {EmasClearanceComponent} from "./visit-details/visit/clearance/emas.clearance.component";
import {EmgsClearanceComponent} from "./visit-details/visit/clearance/emgs.clearance.component";
import {EditBunkerProductComponent} from "./refdata/edit-bunker-product/edit-bunker-product.component";
import {
  EditBunkerProductSupplierComponent
} from "./refdata/edit-bunker-product-supplier/edit-bunker-product-supplier.component";
import {
  TerminalPlanningAdminComponent
} from "./visit-details/visit/berth-visit/berth-visit-details/terminal-planning-admin/terminal-planning-admin.component";
import {
  RegisterTerminalPlanningComponent
} from "./visit-details/visit/berth-visit/berth-visit-details/terminal-planning-admin/register-terminal-planning/register-terminal-planning.component";
import {EditBunkerLicenseComponent} from "./refdata/edit-bunker-license/edit-bunker-license.component";
import {BunkeringComponent} from "./bunkering/bunkering.component";
import {
  SelectionBunkeringBerthVisitsComponent
} from "./bunkering/edit/visit-selection/selection-bunkering-berth-visits.component";
import {EditBunkeringComponent} from "./bunkering/edit/edit-bunkering.component";
import {SelectionBunkerLicenseComponent} from "./bunkering/edit/license-selection/selection-bunker-license.component";
import {
  MultiBerthComponent
} from './visit-details/visit/berth-visit/berth-visit-details/multi-berth/multi-berth.component';
import {AllClearancesStatusComponent} from "./visit-overview/all-clearances-status/all-clearance-status.component";
import {NgChartsModule} from "ng2-charts";
import {MetricsQueryHandlerMock} from "./mocking/metrics-query-handler.mock";
import {CommercialReleaseDashboardQueryHandlerMock} from "./mocking/commercial-release-dashboard-query-handler.mock";
import {EkhClearanceStatusComponent} from "./visit-details/visit/clearance/ekh-clearance-status.component";
import {
  DeclareTranshipmentOwnConsignmentComponent
} from "./transhipment/declare-transhipment/own-consignment/declare-transhipment-own-consignment.component";
import {
  DeclareTranshipmentContainerConsignmentComponent
} from "./transhipment/declare-transhipment/container-consignment/declare-transhipment-container-consignment.component";
import {
  DeclareTranshipmentBulkConsignmentComponent
} from "./transhipment/declare-transhipment/bulk-consignment/declare-transhipment-bulk-consignment.component";
import {TranshipmentFilterComponent} from "./transhipment/transhipment-filter/transhipment-filter.component";
import {TranshipmentOverviewComponent} from "./transhipment/transhipment-overview.component";
import {ViewTranshipmentComponent} from "./transhipment/view-transhipment/view-transhipment.component";
import {TranshipmentGoodDetailsComponent} from "./transhipment/good-details/transhipment-good-details.component";
import {
  TranshipmentGoodsPlacementComponent
} from "./transhipment/goods-placement/transhipment-goods-placement.component";
import {TranshipmentSummaryComponent} from "./transhipment/transhipment-summary/transhipment-summary.component";
import {TranshipmentHistoryComponent} from "./transhipment/transhipment-history/transhipment-history.component";
import {SystemLinkComponent} from './system-link/system-link.component';
import {
  ServiceMonitoringComponent
} from "./system-link/system-link-logged-in/service-monitoring/service-monitoring.component";
import {
  SharedChartLegendComponent
} from "./system-link/system-link-logged-in/service-monitoring/shared-chart-legend/shared-chart-legend.component";
import {
  ServiceMonitoringDoughnutChartComponent
} from "./system-link/system-link-logged-in/service-monitoring/service-monitoring-doughnut-chart/service-monitoring-doughnut-chart.component";
import {
  ServiceMonitoringPieChartComponent
} from "./system-link/system-link-logged-in/service-monitoring/service-monitoring-pie-chart/service-monitoring-pie-chart.component";
import {
  ServiceMonitoringLineChartComponent
} from "./system-link/system-link-logged-in/service-monitoring/service-monitoring-line-chart/service-monitoring-line-chart.component";
import {
  ServiceMonitoringBarChartComponent
} from "./system-link/system-link-logged-in/service-monitoring/service-monitoring-bar-chart/service-monitoring-bar-chart.component";
import {MdohClearanceComponent} from "./visit-details/visit/clearance/mdoh.clearance.component";
import {
  PortPassagePlanningAreaComponent
} from "./visit-details/visit/port-entry-tab/port-entry/port-passage-planning-area/port-passage-planning-area.component";
import {ConfirmationModalComponent} from "./common/confirmation-modal/confirmation-modal.component";
import {TypeaheadWindowComponent} from "./common/typeahead/typeahead-window.component";
import {
  CommercialReleaseEmailPreferencesComponent
} from './preferences/commercial-release-email-preferences/commercial-release-email-preferences.component';
import {
  TerminalPlanningNumberInfoComponent
} from './visit-details/visit/berth-visit/berth-visit-details/terminal-planning-number-info/terminal-planning-number-info.component';
import {
  VisitDeclarationStatusComponent
} from './visit-details/visit-status/visit-declaration-status/visit-declaration-status.component';
import {PortVisitModule} from "./new-portvisit/port-visit.module";
import {AppContext} from "./app-context";
import {PortVisitCommonModule} from "./common/port-visit-common.module";
import {NormalRouteGuard} from "./normal-route.guard";
import {TranshipmentGoodsComponent} from "./transhipment/goods/transhipment-goods.component";
import {
  LabeledDateTimeComponent
} from "./visit-details/visit/berth-visit/berth-visit-details/labeled-date-time.component";
import {BunkeringEtcComponent} from "./visit-details/visit/berth-visit/berth-visit-details/bunkering-etc.component";
import { EditAddressComponent } from './refdata/edit-address/edit-address.component';

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [NormalRouteGuard],
    children: [{
      path: 'details/:crn',
      component: VisitDetailsComponent,
      children: [
        {path: 'visit', component: VisitComponent},
        {path: 'authorise', component: AuthoriseComponent},
        {path: 'financialDeclarant', component: FinancialDeclarantComponent},
        {path: 'security', component: SecurityComponent},
        {path: 'passengers', component: PassengersComponent},
        {path: 'health', component: HealthDeclarationComponent},
        {path: 'waste', component: WasteComponent},
        {path: 'shipStores', component: ShipStoresComponent},
        {path: 'cargoImport', component: CargoImportComponent},
        {path: 'cargoImport/:shortName', component: CargoImportComponent},
        {path: 'cargoImport/:shortName/:filterTerm', component: CargoImportComponent},
        {path: 'cargoImportOverview', component: CargoImportOverviewComponent},
        {path: 'dangerousGoods', component: DangerousGoodsOverviewComponent},
        {path: 'dangerousGoods/:shortName', component: DangerousGoodsComponent},
        {path: 'transit', component: TransitOverviewComponent},
        {path: 'transhipment', component: TranshipmentOverviewComponent},
        {path: 'commercial-release', component: CommercialReleaseOverviewComponent},
        {path: 'history', component: VisitHistoryComponent},
        {path: 'loading', component: CargoLoadingComponent},
        {path: '**', redirectTo: 'visit'}
      ]
    },
      {path: '', component: VisitOverviewComponent},
      {path: 'newVisit', component: NewVisitComponent},
      {path: 'message-overview', component: MessageOverviewComponent},
      {path: 'news', component: NewsComponent},
      {path: 'preferences', component: PreferencesComponent},
      {path: 'refdata', component: RefdataComponent},
      {path: 'admin/panel', component: AdminPanelComponent},
      {path: 'admin/monitoring', component: ServiceMonitoringComponent},
      {path: 'encrypt', component: EncryptComponent},
      {path: 'waste', component: CollectorOverviewComponent},
      {path: 'bunkering', component: BunkeringComponent},
      {path: 'transit', component: TransitOverviewComponent},
      {path: 'transhipment', component: TranshipmentOverviewComponent},
      {path: 'commercial-release', component: CommercialReleaseOverviewComponent},
      {path: 'commercial-release-dashboard', component: CommercialReleaseDashboardComponent},
      {path: 'mid', component: MidOverviewComponent},

      {path: 'fluxImport', component: FluxSearchImportComponent},
      {path: 'fluxExport', component: FluxSearchExportComponent},
      {path: 'guarantees', component: TransitGuaranteesComponent},
      {path: 'transitTemplates', component: TransitTemplatesComponent},
      {path: 'transitAddressBook', component: TransitAddressBookComponent},
      {path: 'addressbook', component: AddressBookComponent},
      {path: 'releaseToParties', component: ReleaseToPartyOverviewComponent},
      {path: 'licensebook', component: LicenseBookComponent},
      {
        path: 'systemlink',
        component: SystemLinkComponent,
        children: [
          {path: 'monitoring', component: ServiceMonitoringComponent}
        ]
      },
      {path: '**', redirectTo: ''}
    ]
  }
];

if (environment.maintenance) {
  appRoutes.unshift({path: '**', component: MaintenanceComponent})
}

const newLookAndFeelModule = [];

if (AppContext.useNewLookAndFeel()) {
  newLookAndFeelModule.push(PortVisitModule);
}

@NgModule({
  declarations: [
    AppComponent,
    UserMenuComponent,
    VisitComponent,
    VisitOverviewComponent,
    NewVisitComponent,
    NewsComponent,
    PreferencesComponent,
    VisitDetailsComponent,
    SecurityComponent,
    WasteComponent,
    PassengersComponent,
    PassengerSummaryComponent,
    ShipStoresComponent,
    AuthoriseComponent,
    FinancialDeclarantComponent,
    NextPortsComponent,
    NextNextPortsComponent,
    VisitHistoryComponent,
    PreviousPortsComponent,
    VisitAdminComponent,
    DangerousGoodsAdminComponent,
    WasteAdminComponent,
    SecurityAdminComponent,
    ShipStoresAdminComponent,
    PassengersAdminComponent,
    EditMovementComponent,
    EditNauticalServiceComponent,
    RegisterAtaComponent,
    RegisterAtdComponent,
    EtaPortAisComponent,
    MessageOverviewComponent,
    ShipToShipActivitiesComponent,
    ConfirmationOfPurchaseComponent,
    NotificationOfDepartureComponent,
    WasteCollectionPdfComponent,
    DeclarationStatusComponent,
    ClearanceStatusComponent,
    ClearanceComponent,
    SspiClearanceComponent,
    EmasClearanceComponent,
    MdohClearanceComponent,
    EmgsClearanceComponent,
    EkhClearanceComponent,
    EkhClearanceStatusComponent,
    AllClearancesStatusComponent,
    DeclarationStatusHealthComponent,
    VisitStatusComponent,
    ReportIssueComponent,
    VesselDetailsComponent,
    DepartureVoyageComponent,
    ArrivalVoyageComponent,
    PortDepartureComponent,
    PortArrivalComponent,
    ExitPointComponent,
    NextExitPointComponent,
    HistoryRibbonComponent,
    CargoImportAdminComponent,
    TimelineComponent,
    DefectsComponent,
    InspectionsComponent,
    HealthDeclarationComponent,
    ExchangeAtBerthComponent,
    HealthDeclarationAdminComponent,
    CargoLoadingComponent,
    LoadListComponent,
    LoadingOverlandersComponent,
    LoadContainerComponent,
    ConfirmationModalComponent,

    //refdata
    RefdataComponent,
    RefdataUpdateComponent,
    EditBerthComponent,
    EditTerminalComponent,
    EditPortComponent,
    EditPortOfCallComponent,
    EditLocationComponent,
    EditPortFacilityComponent,
    EditCarrierComponent,
    EditVesselComponent,
    EditPilotExemptionComponent,
    EditDangerousGoodComponent,
    EditContainerGoodComponent,
    EditSolidGoodComponent,
    EditOilComponent,
    EditGasComponent,
    EditChemicalComponent,
    EditSizeTypeComponent,
    EditTerminalEmailComponent,
    EditVesselServiceComponent,
    EditWasteMaterialComponent,
    EditBunkerProductComponent,
    EditBunkerProductSupplierComponent,
    EditBunkerLicenseComponent,
    FluxSearchImportComponent,
    FluxSearchExportComponent,
    //dangerous goods
    DangerousGoodsComponent,
    StowagesComponent,
    HandlingsComponent,
    EditDischargeComponent,
    EditLoadingComponent,
    EditWashingComponent,
    EditInertingComponent,
    EditStowageComponent,
    DangerousGoodsOverviewComponent,
    EditChemicalComponent,
    EditBlendingComponent,
    EditVentilationComponent,
    RegisterAtdPortComponent,
    PortEntryComponent,
    StormPilotageComponent,
    EditRestowComponent,
    SendEmailTokenComponent,
    CargoImportComponent,
    ImportManifestAdminComponent,
    PartyComponent,
    WarehouseLicenseComponent,
    ConsignmentDetailsComponent,
    ConsignmentsAdminComponent,
    GoodsPlacementComponent,
    GoodsComponent,
    GoodDetailsComponent,
    GoodsDocumentsComponent,
    ConsignmentsComponent,
    ImportContainerComponent,
    ContainersComponent,
    OnCarriageDetailsComponent,
    ImportDeclarationComponent,
    DischargeListComponent,
    DischargeListAdminComponent,
    CargoSummaryComponent,
    AddressBookComponent,
    LicenseBookComponent,
    TransitGuaranteesComponent,
    TransitGuaranteeHistoryComponent,
    PortEntryWarningsComponent,
    OverlandersComponent,
    EntryPointComponent,
    CargoFilterComponent,
    VisitFilterComponent,
    TidalWindowComponent,
    FormattedDateRangeComponent,
    EditDangerInformationComponent,
    PilotBoardingPlaceComponent,
    OrderStatusComponent,
    SelectBerthComponent,
    AgentTransferComponent,
    AddRemoveBerthComponent,
    EntryDependencyIconComponent,
    EarliestTimePortEntryComponent,
    PortEntryTabComponent,
    PilotStationComponent,
    MovementComponent,
    BerthVisitComponent,
    PortArrivalComponent,
    PortDepartureComponent,
    BerthVisitDetailsComponent,
    BerthVisitTimesComponent,
    LabeledDateTimeComponent,
    BunkeringEtcComponent,
    BoatmenToBerthSummaryComponent,
    TugboatsSummaryComponent,
    NauticalServicesComponent,
    MovementSummaryComponent,
    MovementDurationSummaryComponent,
    HistoryComponent,
    PaginationComponent,
    ActionNavBarComponent,
    NauticalServicesInboundComponent,
    EtaEuEntryComponent,
    MovementBetweenBerthsComponent,
    TimelineDateTimeComponent,
    TimelineIconComponent,
    CargoImportOverviewComponent,
    GeneralInformationComponent,
    WasteAtBerthIconComponent,
    PreviousPortsAndFacilitiesComponent,

    //waste collector
    CollectorSummaryTitleBarComponent,
    CollectorOverviewComponent,
    CollectorSummaryComponent,
    WasteCollectionComponent,
    ItineraryComponent,

    //transit
    TransitOverviewComponent,
    TransitGoodComponent,
    TransitGoodsComponent,
    TransitDetailsComponent,
    TransitSummaryComponent,
    TransitGuaranteeComponent,
    GuaranteeAdminComponent,
    TransitTemplatesComponent,
    TransitTemplateComponent,
    TransitDeclarationComponent,
    TransitHistoryComponent,
    TransitFilterComponent,
    TransitAddressBookComponent,
    TransitPartyComponent,
    FluxSearchImportComponent,
    FluxSearchExportComponent,
    TransitGoodsDocumentsComponent,
    TransitDeclarationAdminComponent,
    TransitProofOfArrivalComponent,
    TransitCopyContainersFromConsignmentsComponent,
    TransitCopyBulkFromConsignmentsComponent,

    // Transhipment
    TranshipmentOverviewComponent,
    TranshipmentSummaryComponent,
    TranshipmentFilterComponent,
    DeclareTranshipmentOwnConsignmentComponent,
    DeclareTranshipmentContainerConsignmentComponent,
    DeclareTranshipmentBulkConsignmentComponent,
    ViewTranshipmentComponent,
    TranshipmentGoodDetailsComponent,
    TranshipmentGoodsComponent,
    TranshipmentGoodsPlacementComponent,
    TranshipmentHistoryComponent,

    // commercial release
    CommercialReleaseOverviewComponent,
    CommercialReleaseDeclarationComponent,
    CommercialReleaseDetailsComponent,
    CommercialReleaseFilterComponent,
    CommercialReleaseAdminComponent,
    CommercialReleaseSummaryComponent,
    CommercialReleaseDashboardComponent,
    MidOverviewComponent,
    BatchTransitDeclarationComponent,
    TransitDeclarationSendTriggerInfoComponent,

    TransitDeclarationsFromContainersComponent,
    ReleaseToPartyOverviewComponent,

    // Terminal planning
    TerminalPlanningAdminComponent,
    RegisterTerminalPlanningComponent,

    // Port Call Optimization
    PortPassagePlanningAreaComponent,

    // Bunkering
    BunkeringComponent,
    SelectionBunkeringBerthVisitsComponent,
    SelectionBunkerLicenseComponent,
    EditBunkeringComponent,

    EncryptComponent,
    ReleaseToPartyComponent,
    CommercialReleaseHistoryComponent,
    MaintenanceComponent,
    AdminPanelComponent,
    FormComponent,
    MultiBerthComponent,
    ServiceMonitoringComponent,
    ServiceMonitoringBarChartComponent,
    ServiceMonitoringLineChartComponent,
    ServiceMonitoringDoughnutChartComponent,
    ServiceMonitoringPieChartComponent,
    SharedChartLegendComponent,
    SystemLinkComponent,
    CommercialReleaseEmailPreferencesComponent,
    TerminalPlanningNumberInfoComponent,
    VisitDeclarationStatusComponent,

    // modals
    ModalErrorAutofocus,
     EditAddressComponent

  ],
  imports: [
    RouterModule.forRoot(appRoutes, {enableTracing: false}),
    CookieModule.forRoot(),
    PortVisitCommonModule,
    ...newLookAndFeelModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    DragulaModule.forRoot(),
    NgChartsModule,
    TypeaheadWindowComponent,
  ],
  providers: [
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    TitleCasePipe,
    TimestampPipe,
    CommandGateway,
    QueryGateway,
    EventGateway,
    HardcodedRefdataQueryHandler,
    TravelTimesQueryHandler,
    RefdataQueryHandlerMock,
    BezoekschipQueryHandlerMock,
    BezoekschipCommandHandlerMock,
    GuaranteeCommandHandlerMock,
    UserHandlerMock,
    MetricsQueryHandlerMock,
    CommercialReleaseDashboardQueryHandlerMock,
    NormalRouteGuard
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalConfirmAutofocus,
    ModalErrorAutofocus,
  ],
  exports: []
})
export class AppModule {
  constructor(injector: Injector) {
    InjectorProvider.injector = injector;
    injector.get(HardcodedRefdataQueryHandler);
    injector.get(TravelTimesQueryHandler);

    // Mock users for both docker and local setup
    if (!environment.production) {
      injector.get(UserHandlerMock);
    }

    // Mocks
    if (!environment.production && !environment.docker) {
      injector.get(RefdataQueryHandlerMock);
      injector.get(BezoekschipQueryHandlerMock);
      injector.get(BezoekschipCommandHandlerMock)
      injector.get(GuaranteeCommandHandlerMock);
      injector.get(MetricsQueryHandlerMock);
      injector.get(CommercialReleaseDashboardQueryHandlerMock);
    }
  }
}
