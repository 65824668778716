import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {FacetStats, OverviewFilters} from "../facets/facet-filter/facet-filter.component";
import {openEditModal} from "../../../common/utils";
import {Observable} from "rxjs";
import {FacetUtils} from "../facets/facet-utils";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
  facetUtils = FacetUtils;

  @Input() term: string;
  @Input() overviewFilters: OverviewFilters<any>;
  @Input() isEmpty: boolean;
  @Input() facets: FacetStats[];
  @Input() otherFacets: FacetStats[];
  @Input() facetNameFormatter: (name: string) => string;
  @Input() searchFunction: (term: string) => Observable<any[]> | any[];
  @Input() quickViewsTemplate: TemplateRef<any>;
  @Input() specificFacetFiltersTemplate: TemplateRef<any>;

  @Output() termChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() facetFiltersChanged: EventEmitter<OverviewFilters<any>> = new EventEmitter<OverviewFilters<any>>();
  @Output() resultsFound: EventEmitter<any> = new EventEmitter<any[]>();
  @Output() loadNextPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() filtersCleared: EventEmitter<OverviewFilters<any>> = new EventEmitter<OverviewFilters<any>>();
  from: number = 0;
  endReached: boolean;
  loading: boolean;

  @ViewChild("filtersInModal") filtersTemplate: TemplateRef<any>;

  openFiltersInModal = () => {
    openEditModal(this.filtersTemplate, null, {
      leftToRight: true,
      size: "auto"
    });
  }

  onFacetValuesChange = (overviewFilters: OverviewFilters<any>) => {
    this.endReached = false;
    this.from = 0;
    this.facetFiltersChanged.emit(overviewFilters);
  }
}

export interface FacetedOverviewFilters<T> {
  overviewFilters: OverviewFilters<T>;
}
